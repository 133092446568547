import React from "react";
import { Button } from "react-bootstrap";

const CustomDropdownToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    variant="link"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ padding: '10px', position: 'relative', display: 'block', textDecoration: 'none' }}
  >
    {children}
    &#x25bc;
  </Button>
));

export default CustomDropdownToggle;