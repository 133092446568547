import classNames from "classnames";

const ToggleSwitch = ({ checked = true }) => {
  return (
    <div className="custom-switch">
      <button type="button" className={classNames({
        "btn btn-sm btn-toggle": true,
        "active": checked,
      })} data-toggle="button" aria-pressed="false" onClick={() => null} data-yes="si" data-no="no">
        <div className="handle"/>
      </button>
    </div>
  )
};

export default ToggleSwitch;
