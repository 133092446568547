import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import negativityTransformer from "../../utils/transformers/negativity";
import TableHead from "./tableHead";
import TableBody from "./tableBody";

const TableCompanySummary = () => {

  const { negativity } = useSelector(state => negativityTransformer(state));

  return (
    <Table responsive>
      <TableHead/>
      <TableBody {...negativity}/>
    </Table>
  )
}

export default TableCompanySummary;