import info from './info.svg';
import metrics from './metrics.svg';
import merchandise from './merchandise.svg';
import participation from './participation.svg';
import branchLocation from './branch_location.svg';
import sectionCategoryMerchandise from './section_and_category_merchandise.svg';
import dimensionalInfo from './dimensional_info.svg';
import commercialCreditLine from './commercial_credit_line.svg';

const icons = {
  info,
  metrics,
  merchandise,
  participation,
  'branch-location': branchLocation,
  'section-and-category-merchandise': sectionCategoryMerchandise,
  'dimensional-info': dimensionalInfo,
  'commercial-credit-line': commercialCreditLine,
};

export default icons;