import React, { useRef, useState } from "react";
import MembersDetails from "./memberDetails/MembersDetails";
import ExponentsDetails from "./exponentDetails/ExponentsDetails";
import InvestmentsDetails from "./investmentDetails/InvestmentsDetails";
import DefaultDetails from "./DefaultDetails";
import GraphicData from "./GraphicData";
import CardIcon from "../../card-icon";
import { useSelector } from "react-redux";
import { baseRegistry } from "utils/transformers";
import compassCategory from "utils/transformers/compasCategory";
import territorialIndices from "utils/transformers/territorialIndices";
import exponentData from "utils/transformers/exponents";
import { memberDetailsChart, membersDetails } from "utils/transformers/membersDetails";
import participation from "utils/transformers/participation";
import otherLocation from "utils/transformers/otherLocation";
import { territorialCharts1, territorialCharts2, territorialCharts3 } from "utils/transformers/territorialCharts";
import MembersChart from "../../charts/MembersChart";
import { useProductsAvailable } from "../../../utils/hooks/useProductsAvailable";
import Upselling from "../../upselling";
import useProductUpsellign from "../../../utils/hooks/useProductUpsellign";

const LearnMore = (props) => {
  const detailsTableRef = useRef();
  const rdstate = useSelector((state) => {
    return state;
  });

  const [contentPage, setContentPage] = useState(0);

  const returnToDefault = () => {
    setContentPage(0);
  };

  const handleTableDetailsClick = (element, index) => {
    if (window.innerWidth <= 768) {
      if (detailsTableRef && detailsTableRef.current) {
        detailsTableRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  const compas_category = compassCategory(rdstate);
  const values = baseRegistry(rdstate);
  const territorialData = territorialIndices(rdstate);
  const exponent_Data = exponentData(rdstate);
  const memberData = membersDetails(rdstate);
  const participationData = participation(rdstate);
  const otherLocationData = otherLocation(rdstate);
  const territorialChartsData1 = territorialCharts1(rdstate);
  const territorialChartsData2 = territorialCharts2(rdstate);
  const territorialChartsData3 = territorialCharts3(rdstate);
  const memberDataChart = memberDetailsChart(rdstate);

  const toggleContentPage = (index) => {
    if (index === contentPage) {
      returnToDefault();
    } else {
      setContentPage(index);
    }
  };

  // const isExponentPrAvailable = useProductsAvailable('esponenti_premium');
  // const isSociPrAvailable = useProductsAvailable('soci_premium');
  // const isParticipationPrAvailable = useProductsAvailable('partecipazioni_premium');
  // eslint-disable-next-line no-unused-vars
  const isExponentAvailable = useProductsAvailable(['esponenti_premium', 'esponenti']);
  // eslint-disable-next-line no-unused-vars
  const isSociAvailable = useProductsAvailable(['soci_premium', 'soci']);
  // eslint-disable-next-line no-unused-vars
  const isParticipationAvailable = useProductsAvailable(['partecipazioni_premium', 'partecipazioni']);
  const isExponentAvailableInUpselling = useProductUpsellign(["esponenti_premium", "esponenti"]);
  const isSociAvailableInUpselling = useProductUpsellign(["soci_premium", "soci"]);
  const isParticipationAvailableInUpselling = useProductUpsellign(["partecipazioni_premium", "partecipazioni"]);
  const fullUpsellingBlock =
    isParticipationAvailableInUpselling && isExponentAvailableInUpselling && isSociAvailableInUpselling;

  const upsellingTitleAndDescription = () => {
    if (isExponentAvailableInUpselling && isSociAvailableInUpselling && isParticipationAvailableInUpselling) {
      return {
        title: "Informazioni su cariche e partecipazioni",
        description: "Elenco ed informazioni su soci, esponenti e partecipazioni",
      };
    } else if (!isExponentAvailableInUpselling && isSociAvailableInUpselling && isParticipationAvailableInUpselling) {
      return {
        title: "Informazioni su cariche e partecipazioni",
        description: "Elenco ed informazioni su soci e partecipazioni",
      };
    } else if (isExponentAvailableInUpselling && !isSociAvailableInUpselling && isParticipationAvailableInUpselling) {
      return {
        title: "Informazioni su cariche e partecipazioni",
        description: "Elenco ed informazioni su esponenti e partecipazioni",
      };
    } else if (isExponentAvailableInUpselling && isSociAvailableInUpselling && !isParticipationAvailableInUpselling) {
      return {
        title: "Informazioni su cariche e partecipazioni",
        description: "Elenco ed informazioni su soci e esponenti",
      };
    } else if (isExponentAvailableInUpselling && !isSociAvailableInUpselling && !isParticipationAvailableInUpselling) {
      return {
        title: "Informazioni su cariche e partecipazioni",
        description: "Elenco ed informazioni su esponenti",
      };
    } else if (!isExponentAvailableInUpselling && isSociAvailableInUpselling && !isParticipationAvailableInUpselling) {
      return {
        title: "Informazioni su cariche e partecipazioni",
        description: "Elenco ed informazioni su soci",
      };
    } else if (!isExponentAvailableInUpselling && !isSociAvailableInUpselling && isParticipationAvailableInUpselling) {
      return {
        title: "Informazioni su cariche e partecipazioni",
        description: "Elenco ed informazioni su partecipazioni",
      };
    } else {
      return {
        title: "Informazioni su cariche e partecipazioni",
        description: "Elenco ed informazioni su soci, esponenti e partecipazioni",
      };
    }
  };

  return (
    <div className="learn-more-container">
      <div className="learn-more-container-wrapper">
        <div ref={detailsTableRef} className="row container-wrapper">
          {contentPage === 1 ? (
            <ExponentsDetails returnToDefault={returnToDefault} exponent_Data={exponent_Data} />
          ) : contentPage === 2 ? (
            <MembersDetails returnToDefault={returnToDefault} memberData={memberData} />
          ) : contentPage === 3 ? (
            <InvestmentsDetails returnToDefault={returnToDefault} {...participationData} />
          ) : (
            <DefaultDetails values={values} compas_category={compas_category} {...otherLocationData} />
          )}
          {/* IF DONT HAVE DATA FOR CHART, DONT OPEN ANYTHING OF DETAILS */}
          {/*{memberDataChart ? (*/}
          <div className="col-md-4 company-information">
            <Upselling
              className="h-100 m-w-300"
              show={fullUpsellingBlock}
              title={upsellingTitleAndDescription().title}
              description={upsellingTitleAndDescription().description}
            >
              <div className="cart-title-wrapper company-information-wrapper">
                <CardIcon icon="participation" image />
                <p className="cart-title">Cariche e partecipazioni</p>
              </div>
              <div className="second-level-tabs">
                <Upselling
                  className="h-100 m-w-300"
                  show={
                    !fullUpsellingBlock &&
                    (isParticipationAvailableInUpselling ||
                      isExponentAvailableInUpselling ||
                      isSociAvailableInUpselling)
                  }
                  title={upsellingTitleAndDescription().title}
                  description={upsellingTitleAndDescription().description}
                  redirectUrl={""}
                >
                  {!isExponentAvailableInUpselling && exponent_Data && (
                    <button
                      className={`see_details-button ${contentPage === 1 ? "is-active" : ""}`}
                      onClick={() => {
                        toggleContentPage(1);
                        handleTableDetailsClick();
                      }}
                    >
                      Vedi dettagli Esponenti
                      <span className="add-icon">
                        <i className="bi bi-plus-circle" />
                        <i className="bi bi-dash-circle" />
                      </span>
                    </button>
                  )}
                  {!isSociAvailableInUpselling && memberData && (
                    <button
                      className={`see_details-button ${contentPage === 2 ? "is-active" : ""}`}
                      onClick={() => toggleContentPage(2)}
                    >
                      Vedi dettagli Soci
                      <span className="add-icon">
                        <i className="bi bi-plus-circle" />
                        <i className="bi bi-dash-circle" />
                      </span>
                    </button>
                  )}
                  {!isParticipationAvailableInUpselling && participationData && (
                    <button
                      className={`see_details-button ${contentPage === 3 ? "is-active" : ""}`}
                      onClick={() => toggleContentPage(3)}
                    >
                      Vedi Partecipazioni
                      <span className="add-icon">
                        <i className="bi bi-plus-circle" />
                        <i className="bi bi-dash-circle" />
                      </span>
                    </button>
                  )}
                  {!isSociAvailableInUpselling && memberDataChart && (
                    <div className="doughnut-chart-wrapper">
                      <MembersChart />
                    </div>
                  )}
                </Upselling>
              </div>
            </Upselling>
          </div>
          {/*) : (*/}
          {/*  <div className="col-md-4 content-wrapper">*/}
          {/*    <div className="card-wrapper no-information-content">*/}
          {/*      <h2 className="title">Informazioni su cariche e partecipazioni</h2>*/}
          {/*      <p className="description">Elenco ed informazioni su soci, esponenti e partecipazioni</p>*/}
          {/*      <a href="#link-to-magento" className="backToMagento btn see-other-location-button">*/}
          {/*        Acquista l’informazione*/}
          {/*      </a>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </div>

      {(territorialData || territorialChartsData1 || territorialChartsData2 || territorialChartsData3) && (
        <GraphicData
          territorialData={territorialData}
          territorialChartsData1={territorialChartsData1}
          territorialChartsData2={territorialChartsData2}
          territorialChartsData3={territorialChartsData3}
        />
      )}
    </div>
  );
};

export default LearnMore;
