import React from 'react'
import { Image } from "react-bootstrap";

import arrowup from "icons/arrowup.svg";

const Scroll = ({ onClick }) => {
  return (
    <Image onClick={onClick}  className="scroll" src={arrowup} />
  );
};

export default Scroll
