import React from "react";
import { Table } from "react-bootstrap";
import NegativityRows from "../../../table/__partials/negativity-rows";

const formatAddress = (data) => `${data.indirizzoAbbreviato} ${data.ioCivico} - ${data.ioCap} ${data.ioComune}` || "--";

const DetailsOfInvestment = ({ detailsPerson, premium = false }) => {
  return (
    <Table responsive className="personDetails-table">
      {/*<caption className="caption-tables">Bilancio di riferimento anno 2019</caption>*/}
      <thead>
        <tr>
          <th>{detailsPerson.denominazioneNormP || "-"}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Forma Giuridica:</td>
          <td>{detailsPerson.descFormagiuridica || "--"}</td>
        </tr>
        <tr>
          <td>Indirizzo:</td>
          <td>{detailsPerson.indirizzoNorm || formatAddress(detailsPerson) || "--"}</td>
        </tr>
        <tr>
          <td>Cciaa Rea:</td>
          <td>{detailsPerson.cciaaP + " " + detailsPerson.reaP || "--"}</td>
        </tr>
        <tr>
          <td>Pec:</td>
          <td>{detailsPerson.pecP || "--"}</td>
        </tr>
        <tr>
          <td>Stato attività infocamere:</td>
          <td>{detailsPerson.descStatoAttivitaInfocamere || "--"}</td>
        </tr>
        <tr>
          <td>Partita Iva:</td>
          <td>{detailsPerson.partitaivaP || "--"}</td>
        </tr>
        <tr>
          <td>Tipologia Sede:</td>
          <td>{detailsPerson.descDescrizionesede || "--"}</td>
        </tr>
        <tr>
          <td>Percentuale Quota Parziale:</td>
          <td>{detailsPerson.percentualeQuotaParziale || "--"}</td>
        </tr>
        <tr>
          <td>Percentuale Quota Consolidata:</td>
          <td>{detailsPerson.percentualeQuotaCons || "--"}</td>
        </tr>
        {premium && (
          <tr>
            <td>Score:</td>
            <td>{detailsPerson.negativita[0].fascia || "--"}</td>
          </tr>
        )}
        {premium && Array.isArray(detailsPerson.negativita) && (
          <NegativityRows
            negativity={detailsPerson.negativita[0].negativita === "N"}
            data={detailsPerson.negativita[0]}
            premium={premium}
          />
        )}
        {!premium && Array.isArray(detailsPerson.flag) && (
          <NegativityRows
            negativity={detailsPerson.flag[0].negativita === "N"}
            data={detailsPerson.flag[0]}
            premium={premium}
          />
        )}
      </tbody>
    </Table>
  );
};

export default DetailsOfInvestment;
