const participation = (state) => {
  // const data = state.documents.values.partecipazioni_premium ?
  //   state.documents.values.partecipazioni_premium.partecipazioniRaggruppatePremium :
  //   (state.documents.values.partecipazioni ? state.documents.values.partecipazioni.partecipazioniRaggruppate : {});

  let data = null;
  let premium = null;

  if (state.documents.values.partecipazioni_premium && state.documents.values.partecipazioni_premium.partecipazioniRaggruppatePremium) {
    data = state.documents.values.partecipazioni_premium.partecipazioniRaggruppatePremium;
    premium = true;
  } else if (state.documents.values.partecipazioni && state.documents.values.partecipazioni.partecipazioniRaggruppate) {
    data = state.documents.values.partecipazioni.partecipazioniRaggruppate;
    premium = false;
  }

  if (data != null && Array.isArray(data) && data.length > 0) {
    const participationData = data;
    return {
      participationData,
      premium,
    };
  }
  return null;
};
export default participation;
