import { useSelector } from "react-redux";

export const useDocumentAvailable = key => {
  const documents = useSelector(state => state.documents.values)
  if (Array.isArray(key)) {
    for (let i = 0; i < key.length; i++) {
      const isAvailable = Boolean(documents?.[key[i]]);
      if (isAvailable) {
        return true
      }
    }
    return false;
  }

  return Boolean(documents?.[key])
}

export default useDocumentAvailable;
