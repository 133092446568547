import RatingLayoutOne from "./ratingLayoutOne";

const Rating = ({ layout, ...rest }) => {
  const renderLayout = () => {
    switch (layout) {
      case "one":
        return <RatingLayoutOne {...rest} />;
      default:
        return <RatingLayoutOne {...rest} />;
    }
  };

  return renderLayout();
};

export default Rating;
