const mergeNegativity = (state, data) => {
  const esponentiRaggruppati = state.documents.values?.esponenti_premium?.listaEsponentiPremium?.esponentiRaggruppati;
  if (data && Array.isArray(esponentiRaggruppati) && esponentiRaggruppati.length > 0) {
    return data.map((item) => {
      const esponentiItem = esponentiRaggruppati.find((i) => i.denominazione === item.denominazione);
      const negativity = esponentiItem?.negativita;
      if (negativity) {
        return {
          ...item,
          ...{
            negativityData: negativity,
          },
        };
      }

      return item;
    });
  }

  return data;
};

const sinteziEsponenti = (state) => {
  let dataEsponenti = null;
  let esponentiPremium = false;
  if (typeof state.documents.values.esponenti_premium !== "undefined" && state.documents.values.esponenti_premium) {
    dataEsponenti =
      state.documents.values.esponenti_premium.listaEsponentiPremium &&
      Array.isArray(state.documents.values.esponenti_premium.listaEsponentiPremium.listaNegativita)
        ? state.documents.values.esponenti_premium.listaEsponentiPremium.listaNegativita
        : [];
    esponentiPremium = true;
    dataEsponenti = mergeNegativity(state, dataEsponenti);
  }

  if (typeof state.documents.values.esponenti !== "undefined" && state.documents.values.esponenti) {
    dataEsponenti =
      state.documents.values.esponenti.listaEsponenti &&
      Array.isArray(state.documents.values.esponenti.listaEsponenti.listaFlag)
        ? state.documents.values.esponenti.listaEsponenti.listaFlag
        : [];
    esponentiPremium = false;
  }

  return {
    dataEsponenti,
    esponentiPremium,
  };
};

export default sinteziEsponenti;
