import React from "react";

const TableHead = ({ annoFirst, annoSecond, annoThird }) => {
  return (
    <>
      <th />
      <th />
      <th>
        <div className="tags blue">{annoFirst}</div>
      </th>
      <th>
        <div className="tags blue">{`Cagr ${annoFirst || "-"} - ${annoThird ? annoThird : annoSecond || "-"}`}</div>
      </th>
      <th>
        <div className="tags blue">{`Media di settore ${annoFirst || "-"}`}</div>
      </th>
      {annoSecond && (
        <th>
          <div className="tags orange">{annoSecond || "-"}</div>
        </th>
      )}
      {annoThird && (
        <th>
          <div className="tags green">{annoThird || "-"}</div>
        </th>
      )}
    </>
  );
};

export default TableHead;
