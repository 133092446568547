import React from "react";
import { useSelector } from "react-redux";
import { budgetFourDIc } from "utils/transformers";
import { Row, Col } from "react-bootstrap";
import { injectIntl } from "react-intl";
import Tag from "./UI/Tag";

import Table from "components/table/Table";
import { BudgetChart1, BudgetChart2, BudgetChart3 } from "../../charts";

const COLOR_LABELS = {
  0: { blue: true },
  1: { orange: true },
  2: { green: true },
}

const Principali = ({ intl }) => {
  const {
    tableDataBilancioOne,
    tableDataBilancioTwo,
    graficoBilancio1,
    graficoBilancio2,
    graficoBilancio3,
  } = useSelector((state) => budgetFourDIc(state));

  const labels = graficoBilancio1 && graficoBilancio1.data ? JSON.parse(JSON.stringify(graficoBilancio1.data.labels)) : [];

  return (
    <Row>
      <Col>
        <div className="main">
          {tableDataBilancioOne && (<Row>
            <Col>
              <h1 className="analyze-title">
                {intl.formatMessage({ id: "app.analyze.title1" })}
              </h1>
              {labels && (<div className="tags-wrapper">
                {labels.sort((a, b) => (b - a)).map((item, index) => <Tag {...COLOR_LABELS[index]} key={item}>{item}</Tag>)}
              </div>)}
            </Col>
          </Row>)}
          {tableDataBilancioOne && (<Row>
            {(graficoBilancio1 && graficoBilancio1.data) && (<Col lg={4}>
              <BudgetChart1/>
            </Col>)}
            {(graficoBilancio2 && graficoBilancio2.data) && (<Col lg={4}>
              <BudgetChart2/>
            </Col>)}
            {(graficoBilancio3 && graficoBilancio3.data) && (<Col lg={4}>
              <BudgetChart3/>
            </Col>)}
          </Row>)}
          {tableDataBilancioOne && (<Row>
            <Col>
              <Table model={tableDataBilancioOne}/>
            </Col>
          </Row>)}
          {tableDataBilancioTwo && (<Row>
            <Col>
              <h1 className="analyze-title mb-5">
                {intl.formatMessage({ id: "app.analyze.title2" })}
              </h1>
              <Table model={tableDataBilancioTwo}/>
            </Col>
          </Row>)}
        </div>
      </Col>
    </Row>
  );
};

export default injectIntl(Principali);
