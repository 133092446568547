import React from "react";

const BranchAddressContactInfo = ({ address, phoneNumber, email, web }) => (
  <p>
    <strong>Indirizzo:</strong> {address || '--'} <br/>
    <strong>Tel.:</strong> {phoneNumber || '--'} <br/>
    <strong>Email:</strong> {email || '--'} <br/>
    <strong>Web:</strong> {web || '--'} <br/>
  </p>
);

export default BranchAddressContactInfo;
