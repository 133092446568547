import classNames from "classnames";

const RatingSwitch = ({ top, middle, bottom }) => (
  <div
    className={classNames("rating-switch", {
      "switch-top": top,
      "switch-middle": middle,
      "switch-bottom": bottom,
    })}
  >
    <div className={classNames({
      "switch-light-top": top,
      "switch-light-middle": middle,
      "switch-light-bottom": bottom
    })} />
  </div>
);

export default RatingSwitch;