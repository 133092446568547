import React from "react";
import Rating from "../rating";
import Upselling from "../upselling";
import useProductsAvailable from "../../utils/hooks/useProductsAvailable";
import useDocumentAvailable from "../../utils/hooks/useDocumentAvailable";

const RatingWithUpSelling = ({ checked, negativity }) => {
  const isAvailableInProducts = useProductsAvailable('rating_fido');
  const isAvailableInDocuments = useDocumentAvailable('outputRatingFido');
  const isUpselling = isAvailableInProducts && !isAvailableInDocuments; // useProductUpsellign("rating_fido");
  return (
    <Upselling
      show={isUpselling}
      className="h-100"
      title="Informazioni per la valutazione dell’azienda"
      description="Rating, fido ed eventi negativi"
    >
      {!isUpselling && <Rating layout="one" checked={checked} negativity={negativity} />}
    </Upselling>
  );
};

export default RatingWithUpSelling;
