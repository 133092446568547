const territorialIndices = state => {
  const data = typeof state.documents.values.indiciTerritoriali !== 'undefined' &&
  state.documents.values.indiciTerritoriali &&
  Array.isArray(state.documents.values.indiciTerritoriali.indiciTerritoriali) ?
    state.documents.values.indiciTerritoriali.indiciTerritoriali[0] : null;

  if (data) {
    const potentialityDesc = data.descAtecoDistrettoComp || '--';
    const idxProtestiCom = data.idxProtestiCom.toFixed(2) || '--';
    const idxProtestiIta = data.idxProtestiIta.toFixed(2) || '--';
    const idxPregiudizievoliCom = data.idxPregiudizievoliCom.toFixed(2) || '--';
    const idxPregiudizievoliIta = data.idxPregiudizievoliIta.toFixed(2) || '--';
    const idxProcedureCom = data.idxProcedureCom.toFixed(2) || '--';
    const idxProcedureIta = data.idxProcedureIta.toFixed(2) || '--';
    const idxProtestiDiv = data.idxProtestiDiv.toFixed(2) || '--';
    const idxPregiudizievoliDiv = data.idxPregiudizievoliDiv.toFixed(2) || '--';
    const idxProcedureDiv = data.idxProcedureDiv.toFixed(2) || '--';
    const lm_districtSpecification = data.distrettoSpec || '--';
    const lm_descTypoPrev = data.descrTipPrev || '--'
    const lm_divisionCodeAteco = data.codeAtecoDivisione || '--'

    return {
      potentialityDesc,
      idxProtestiCom,
      idxProtestiIta,
      idxPregiudizievoliCom,
      idxPregiudizievoliIta,
      idxProcedureCom,
      idxProcedureIta,
      idxProtestiDiv,
      idxPregiudizievoliDiv,
      idxProcedureDiv,
      lm_districtSpecification,
      lm_descTypoPrev,
      lm_divisionCodeAteco
    }
  }

  return null;
}

export default territorialIndices;