import { Button, Col, Row } from "react-bootstrap";
import React from "react";

const TerritoryAndSectorInfo = ({
  potentialityDesc,
  idxProtestiCom,
  idxProtestiIta,
  idxPregiudizievoliCom,
  idxPregiudizievoliIta,
  idxProcedureCom,
  idxProcedureIta,
  idxProtestiDiv,
  idxPregiudizievoliDiv,
  idxProcedureDiv,
}) => {
  const checkLabel = (firstValue, secondValue) => {
    if (firstValue < secondValue) {
      return "label-success";
    } else {
      return "label-danger";
    }
  };

  return (
    <React.Fragment>
      <h3 className="text-center">Informazioni sul territorio e settore</h3>
      <Row>
        <Col>
          <p>
            <strong>Potenzialità:</strong> {potentialityDesc}
          </p>
        </Col>
      </Row>
      <strong>Rischiosità:</strong>
      <Row className="align-items-center">
        <Col md={5}>
          <p>
            <br />
            Incidenza eventi negativi nel territorio di riferimento vs media nazionale
          </p>
        </Col>
        <Col>
          <Row>
            <Col>
              <div className="text-center">
                <p className="mb-2">
                  <strong>protesti</strong>
                </p>
                <Button type="button" variant={checkLabel(idxProtestiCom, idxProtestiIta)}>
                  {idxProtestiCom}% vs {idxProtestiIta}%
                </Button>
              </div>
            </Col>
            <Col>
              <div className="text-center">
                <p className="mb-2">
                  <strong>pregiudizievoli</strong>
                </p>
                <Button type="button" variant={checkLabel(idxPregiudizievoliCom, idxPregiudizievoliIta)}>
                  {idxPregiudizievoliCom}% vs {idxPregiudizievoliIta}%
                </Button>
              </div>
            </Col>
            <Col>
              <div className="text-center">
                <p className="mb-2">
                  <strong>procedure</strong>
                </p>
                <Button type="button" variant={checkLabel(idxProcedureCom, idxProcedureIta)}>
                  {idxProcedureCom}% vs {idxProcedureIta}%
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      <Row className="align-items-center">
        <Col md={5}>
          <p>Incidenza eventi negativi nel settore di riferimento vs media nazionale</p>
        </Col>
        <Col>
          <Row>
            <Col>
              <div className="text-center">
                <p className="mb-2">
                  <strong>protesti</strong>
                </p>
                <Button type="button" variant={checkLabel(idxProtestiDiv, idxProtestiIta)}>
                  {idxProtestiDiv}% vs {idxProtestiIta}%
                </Button>
              </div>
            </Col>
            <Col>
              <div className="text-center">
                <p className="mb-2">
                  <strong>pregiudizievoli</strong>
                </p>
                <Button type="button" variant={checkLabel(idxPregiudizievoliDiv, idxPregiudizievoliIta)}>
                  {idxPregiudizievoliDiv}% vs {idxPregiudizievoliIta}%
                </Button>
              </div>
            </Col>
            <Col>
              <div className="text-center">
                <p className="mb-2">
                  <strong>procedure</strong>
                </p>
                <Button type="button" variant={checkLabel(idxProcedureDiv, idxProcedureIta)}>
                  {idxProcedureDiv}% vs {idxProcedureIta}%
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TerritoryAndSectorInfo;
