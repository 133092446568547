import axios from "axios";
import { BASE_URL } from "../../constants/api";

const Client = axios.create({
  baseURL: BASE_URL,
  responseType: "json",
  headers: {
    'Authorization': 'Bearer 123456789',
    'Content-Type': 'application/json'
  }
});

export default Client;