import { convertDate } from "../../utils/formaters";
import { formattedNumber } from "../../utils/formaters";

const TableBody = ({
  dataLevataRecente,
  totImportoProtesti,
  dataPregRecente,
  totImportoPregiudizievoli,
  dataPcorRecente,
  descPcorRecente,
  codicePcorRecente,
  numProtesti,
  numPregTotale,
  numPcor,
}) => (
  <tbody>
    <tr>
      <td>Protesti</td>
      <td className="table-td">{convertDate(dataLevataRecente)}</td>
      <td className="table-td">{numProtesti}</td>
      <td className="table-td">{formattedNumber(totImportoProtesti)} €</td>
    </tr>
    <tr>
      <td>Pregiudizievoli</td>
      <td className="table-td">{convertDate(dataPregRecente)}</td>
      <td className="table-td">{numPregTotale}</td>
      <td className="table-td">{formattedNumber(totImportoPregiudizievoli)} €</td>
    </tr>
    <tr>
      <td>Procedure</td>
      <td className="table-td">{convertDate(dataPcorRecente)}</td>
      <td className="table-td">{numPcor}</td>
      <td className="table-td">{codicePcorRecente || "--"} </td>
    </tr>
  </tbody>
);

export default TableBody;
