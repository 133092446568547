import { injectIntl } from "react-intl";
import CardWrapper from "../../card";
import Wrapper from "../../partial/wrapper";
import EconomicPotentialChart from "../../charts/EconomicPotentialChart";
import NationalAverageNegativeEventsChart from "../../charts/NationalAverageNegativeEventsChart";
import NationalAverageNegativeEventsPerSectorChart from "../../charts/NationalAverageNegativeEventsPerSectorChart";

const GraphicData = (props) => {
  const { territorialData, territorialChartsData1, territorialChartsData2, territorialChartsData3, intl } = props;
  return (
    <div className="graph-container">
      <div className="container">
        <div className="row">
          <div className="col-md-4 polar-are-graph-wrapper">
            <p className="graph-title">{intl.formatMessage({ id: "app.learn_more.chart1_title" })}</p>
            <EconomicPotentialChart data={territorialChartsData1} />
          </div>
          <div className="col-md-4 polar-are-graph-wrapper">
            <p className="graph-title">{intl.formatMessage({ id: "app.learn_more.chart2_title" })}</p>
            <NationalAverageNegativeEventsChart data={territorialChartsData2} />
          </div>
          <div className="col-md-4 polar-are-graph-wrapper">
            <p className="graph-title">{intl.formatMessage({ id: "app.learn_more.chart3_title" })}</p>
            <NationalAverageNegativeEventsPerSectorChart data={territorialChartsData3} />
          </div>
          <div className="col-md-12 pt-4">
            <p className="text-center">
              I dati di ciascun grafico sono normalizzati rispetto ai valori di riferimento del grafico stesso
            </p>
          </div>
        </div>
      </div>
      <Wrapper className="graph-information-wrapper">
        <CardWrapper
          icon="metrics"
          horizontal
          title={intl.formatMessage({ id: "app.learn_more.info_territory_and_sector" })}
          type={2}
        >
          <div className="col-md-3">
            <p className="graph-title m-0">{intl.formatMessage({ id: "app.learn_more.territorial_data" })}</p>
            <p className="graph-description">
              {territorialData.lm_districtSpecification ? territorialData.lm_districtSpecification : "--"}
              <br />
              {territorialData.potentialityDesc && territorialData.potentialityDesc !== "ND"
                ? territorialData.potentialityDesc
                : ""}
            </p>
          </div>
          <div className="col-md-3">
            <p className="graph-title m-0">{intl.formatMessage({ id: "app.learn_more.type_of_prevalence_zone" })}</p>
            <p className="graph-description">
              {territorialData.lm_descTypoPrev ? territorialData.lm_descTypoPrev : "--"}
            </p>
          </div>
          <div className="col-md-3">
            <p className="graph-title m-0">{intl.formatMessage({ id: "app.learn_more.reference_sector" })}</p>
            <p className="graph-description">
              {" "}
              ATECO {territorialData.lm_divisionCodeAteco ? territorialData.lm_divisionCodeAteco : "--"}{" "}
            </p>
          </div>
        </CardWrapper>
      </Wrapper>
    </div>
  );
};

export default injectIntl(GraphicData);
