import React, { useRef, useState } from "react";
import DetailsOfMember from "./DetailsOfMember";
import CardIcon from "../../../card-icon";
import { negativityValue, renderNegativityFlag } from "../../../../utils/commmon/helpers";

const MembersDetails = (props) => {
  const detailsTableRef = useRef();
  const { returnToDefault, memberData } = props;
  const [fiscalCode, setFiscalCode] = useState("");
  const [detailsPerson, setDetailsPerson] = useState([]);
  const [isClicked, setIsClicked] = useState();

  const getTrClicked = (index) => {
    setIsClicked(index);
  };

  const handleTableDetailsClick = (element, index) => {
    if (fiscalCode && (fiscalCode === element.codicefiscale || fiscalCode === "fiscal_code_" + index)) {
      setFiscalCode(null);
      setIsClicked(null);
    } else {
      setFiscalCode(element.codicefiscale);
      if (!element.codicefiscale) {
        setFiscalCode("fiscal_code_" + index);
      }
      setDetailsPerson(element);
      getTrClicked(index);
      if (window.innerWidth <= 768) {
        if (detailsTableRef && detailsTableRef.current) {
          detailsTableRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    }
  };
  return (
    <div className="col-md-8 details-content">
      <div className="content-wrapper">
        <div className="company-information rounded_borders">
          <div className="cart-title-wrapper mt-4">
            <CardIcon icon="participation" image/>
            <div className="title-subtitle-wrapper">
              <p className="cart-title">Dettagli Soci</p>
            </div>
            <button className="close-icon" onClick={() => returnToDefault()}>
              <i className="bi bi-x-circle"/>
            </button>
          </div>
          <div className="row">
            <div className="col-md-12 col-xl-6 table-responsive">
              <table className="table exponentsDetails-table table-hover">
                <thead>
                <tr>
                  <th scope="col">Nome/Ragione sociale</th>
                  <th scope="col">Negatività</th>
                  <th scope="col">Quote</th>
                  <th scope="col">Codice fiscale</th>
                  <th scope="col"/>
                </tr>
                </thead>
                <tbody>
                {memberData &&
                memberData.memberData.map((element, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${isClicked === index ? "isClicked" : "default"}`}
                      onClick={() => handleTableDetailsClick(element, index)}
                    >
                      <td>{element.denominazioneNorm}</td>
                      {renderNegativityFlag(negativityValue(element, memberData.premium))}
                      <td>{element.percentualeQuotaOutput} %</td>
                      <td>{element.codicefiscale}</td>
                      <td className="add-button-table">
                        <i className="bi bi-plus-circle"/>
                        <i className="bi bi-dash-circle"/>
                      </td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
            <div className="col-md-12 col-xl-6">
              {fiscalCode && fiscalCode !== "" && (
                <DetailsOfMember detailsPerson={detailsPerson} premium={memberData.premium}/>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembersDetails;
