import { Card, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import CardIcon from "../card-icon";
import React from "react";

const IconTitle = ({ icon, title, close, onClose }) => (<div className="card-wrapper--header">
  {icon && (
    <CardIcon icon={icon} image/>
  )}
  {title && (
    <Card.Text className="card-header-primary ml-card-header-primary mt-3">{title}</Card.Text>
  )}
  {close && (
    <button className="close-icon" onClick={() => typeof onClose === 'function' ? onClose() : null}>
      <i className="bi bi-x-circle"/>
    </button>
  )}
</div>);

const CardWrapper =
  ({
     children,
     icon,
     title,
     noPadding,
     centered,
     horizontal,
     iconSize = 3,
     type = null,
     close = false,
     onClose = undefined,
     ...rest
   }) => (
    <Card
      className={`card-wrapper ${type ? `card-wrapper-${type}` : ''} ${noPadding ? 'no-padding' : ''} ${centered ? 'centered' : ''}`} {...rest}>
      <Card.Body style={{ padding: 0, margin: 0 }}>
        {horizontal && (icon || title) && (<Row>
          <Col xs={12} sm={12} md={iconSize}>
            <IconTitle icon={icon} title={title} close={close} onClose={onClose}/>
          </Col>
          {children}
        </Row>)}
        {!horizontal && (icon || title) && <IconTitle icon={icon} title={title} close={close} onClose={onClose}/>}
        {!horizontal && children}
      </Card.Body>
    </Card>
  )

CardWrapper.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.oneOf(['info', 'merchandise', 'metrics', 'participation', 'branch-location', 'commercial-credit-line', 'dimensional-info', 'info-territory-and-sector', 'section-and-category-merchandise']),
  title: PropTypes.string,
  noPadding: PropTypes.bool,
  centered: PropTypes.bool,
  horizontal: PropTypes.bool,
  iconSize: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
}

export default CardWrapper;