import { useSelector } from "react-redux";
import { memberDetailsChart } from "../../utils/transformers/membersDetails";
import CustomChart from "../chartjs/chart";

const MembersChart = () => {
  const memberDataChart = useSelector(state => memberDetailsChart(state));
  const data = JSON.parse(JSON.stringify(memberDataChart));
  const plugins = {
    zoom: {
      enabled: false,
    },
    pan: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      labels: {

      }
    }
  }
  return (
    <CustomChart type="doughnut" data={data.data} plugins={plugins} width={450} height={450}/>
  )
};

export default MembersChart;