const exponentData = (state) => {
  // const data =
  //   state.documents.values.esponenti && state.documents.values.esponenti.listaEsponenti
  //     ? state.documents.values.esponenti_premium.listaEsponentiPremium.esponentiRaggruppati
  //     : state.documents.values.esponenti_premium && state.documents.values.esponenti_premium.listaEsponentiPremium
  //     ? state.documents.values.esponenti_premium.listaEsponentiPremium.esponentiRaggruppati
  //     : {};
  let data = null;
  let premium = false;
  if (state.documents.values.esponenti_premium && state.documents.values.esponenti_premium.listaEsponentiPremium) {
    data = state.documents.values.esponenti_premium.listaEsponentiPremium.esponentiRaggruppati;
    premium = true;
  } else if (state.documents.values.esponenti && state.documents.values.esponenti.listaEsponenti) {
    data = state.documents.values.esponenti.listaEsponenti.esponentiRaggruppati;
    premium = false;
  } else {
    data = null;
    premium = false;
  }

  if (Array.isArray(data) && data.length > 0) {
    const exponent_Data = data;
    return {
      premium,
      exponent_Data,
    };
  }
  return null;
};
export default exponentData;
