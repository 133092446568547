import { createSlice } from "@reduxjs/toolkit";
import { getDocuments } from "../actions/documentActions";

export const initialState = {
  values: {},
  loading: true,
  error: false,
  errorBag: [],
  requestCompleted: false,
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDocuments.pending.type, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getDocuments.fulfilled.type, (state, action) => {
        state.values = {
          ...state.values,
          ...action.payload.documents,
        };
        state.loading = false;
        state.error = false;
        state.requestCompleted = true;
      })
      .addCase(getDocuments.rejected.type, (state, action) => {
        state.loading = false;
        state.error = true;
        state.requestCompleted = true;
      });
  },
});

export default documentsSlice.reducer;
