import { Nav, Image, Navbar, Container, Dropdown } from "react-bootstrap";
import logo from "../../resize_logo.png";
import { matchPath, withRouter, generatePath } from "react-router";
import { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import CustomDropdownToggle from "../dropdown/customDropdownToggle";

const Header = props => {
  const { history, location, intl, showTabs, onSwitchTab } = props;
  const display = showTabs ? 'flex' : 'none';

  const [tabsTitle, settabsTitle] = useState('Report Premium acquistato il 12/01/2021')

  const [dropdownMenuTitle, setDropdownMenuTitle] = useState(intl.formatMessage({ id: 'app.learn_more' }));

  const matchPath1 = matchPath(location.pathname, {
    path: '/:idOrder/:idClient',
    exact: true,
    strict: true
  });

  const matchPath2 = matchPath(location.pathname, {
    path: '/:idOrder/:idClient/:tab',
    exact: true,
    strict: true
  });
  const match = matchPath1 || matchPath2;

  useEffect(() => {
    if (showTabs) {
      const tab = match?.params?.tab;
      switch (tab) {
        case 'learn-more':
          settabsTitle('Dati anagrafici e di settore');
          break;
        case 'analyze':
          settabsTitle('Dati finanziari');
          break;
        case 'evaluate':
          settabsTitle('Affidabilità dell’azienda');
          break;
        default:
          settabsTitle('Dati anagrafici e di settore');
          break;
      }
    } else {
      settabsTitle('Report Premium acquistato il 12/01/2021');
    }
  }, [showTabs, match?.params?.tab]);

  useEffect(() => {
    const tab = match?.params?.tab;
    onSwitchTab(tab);
    switch (tab) {
      case 'learn-more':
        setDropdownMenuTitle(intl.formatMessage({ id: 'app.learn_more' }));
        break;
      case 'analyze':
        setDropdownMenuTitle(intl.formatMessage({ id: 'app.analyze' }));
        break;
      case 'evaluate':
        setDropdownMenuTitle(intl.formatMessage({ id: 'app.evaluate' }));
        break;
      default:
        setDropdownMenuTitle(intl.formatMessage({ id: 'app.learn_more' }));
        break;
    }
  }, [intl, match?.params?.tab, onSwitchTab]);

  const handleScrollTab = () => {
    const tabContent = document.querySelector('.tab-content');
    window.scrollTo({
      behavior: "smooth",
      top: (tabContent.offsetTop - 100)
    });
  }

  return (
    <>
      <Navbar expand={false} variant="light" bg="white" fixed="top" className="pb-0">
        <Container fluid>
          <Navbar.Brand href="#">
            <Image fluid src={logo} style={{ width: 'auto', float: 'left' }}/>
          </Navbar.Brand>
          <Navbar.Text className="justify-content-end main-tab-title">
            {tabsTitle}
          </Navbar.Text>
        </Container>
        <Container fluid style={{ display }}>
          <Nav variant="tabs" defaultActiveKey="learn-more" navbar={false} className="d-sm-none d-md-flex">
            <Nav.Item>
              <Nav.Link eventKey="learn-more" onSelect={(eventKey) => {
                const pathname = generatePath("/:idOrder/:idClient/:tab", {
                  idOrder: match.params.idOrder,
                  idClient: match.params.idClient,
                  tab: eventKey,
                });
                history.push({ pathname, search: location.search });
                onSwitchTab(eventKey);
                handleScrollTab();
                settabsTitle('Dati anagrafici e di settore')
              }}>{intl.formatMessage({ id: 'app.learn_more' })}</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="analyze" onSelect={(eventKey) => {
                const pathname = generatePath("/:idOrder/:idClient/:tab", {
                  idOrder: match.params.idOrder,
                  idClient: match.params.idClient,
                  tab: eventKey,
                });
                history.push({ pathname, search: location.search });
                onSwitchTab(eventKey);
                handleScrollTab();
                settabsTitle('Dati finanziari')

              }}>{intl.formatMessage({ id: 'app.analyze' })}</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="evaluate" onSelect={(eventKey) => {
                const pathname = generatePath("/:idOrder/:idClient/:tab", {
                  idOrder: match.params.idOrder,
                  idClient: match.params.idClient,
                  tab: eventKey,
                });
                history.push({ pathname, search: location.search });
                onSwitchTab(eventKey);
                handleScrollTab();
                settabsTitle('Affidabilità dell’azienda')

              }}>{intl.formatMessage({ id: 'app.evaluate' })}</Nav.Link>
            </Nav.Item>

          </Nav>
          <Dropdown className="d-sm-block d-md-none">
            <Dropdown.Toggle as={CustomDropdownToggle}>
              {dropdownMenuTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="learn-more" onSelect={(eventKey) => {
                history.push({ pathname: eventKey, search: location.search });
                onSwitchTab(eventKey);
                setDropdownMenuTitle(intl.formatMessage({ id: 'app.learn_more' }));
              }}>
                {intl.formatMessage({ id: 'app.learn_more' })}
              </Dropdown.Item>
              <Dropdown.Item eventKey="analyze" onSelect={(eventKey) => {
                history.push({ pathname: eventKey, search: location.search });
                onSwitchTab(eventKey);
                setDropdownMenuTitle(intl.formatMessage({ id: 'app.analyze' }));
              }}>
                {intl.formatMessage({ id: 'app.analyze' })}
              </Dropdown.Item>
              <Dropdown.Item eventKey="evaluate" onSelect={(eventKey) => {
                history.push({ pathname: eventKey, search: location.search });
                onSwitchTab(eventKey);
                setDropdownMenuTitle(intl.formatMessage({ id: 'app.evaluate' }));
              }}>
                {intl.formatMessage({ id: 'app.evaluate' })}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Container>
      </Navbar>
    </>
  )
}

export default withRouter(injectIntl(Header));
