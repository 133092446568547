import PropTypes from "prop-types";
import icons from "../../icons";

const CardIcon = ({ icon, image = false, alt = null }) => image ? (
  <img src={icons[icon]} alt={alt} className="card-wrapper--icon-img" />
) : (
  <i className={`card-wrapper--icon custom-icon icon-${icon}`}/>
);

CardIcon.propTypes = {
  icon: PropTypes.oneOf(['info', 'merchandise', 'metrics', 'participation', 'branch-location', 'commercial-credit-line', 'dimensional-info', 'info-territory-and-sector', 'section-and-category-merchandise']),
}

export default CardIcon;