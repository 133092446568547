import React from "react";
import { Container } from "react-bootstrap";

import Principali from "./Principali";
import Ultimi from "./Ultimi";

const Analyze = () => {
  return (
    <div id="analyze">
      <Container fluid>
        <Principali />
        <Ultimi />
      </Container>
    </div>
  );
};

export default Analyze;
