import { Table } from "react-bootstrap";
import React, { useState } from "react";
import { negativityValue, renderNegativityFlag } from "../../../../../utils/commmon/helpers";

const InvestmentsDetailsTable = ({ data, onElementSelect, premium }) => {
  const [isClicked, setIsClicked] = useState();

  return (
    <Table hover responsive className="exponentsDetails-table">
      <thead>
        <tr>
          <th scope="col">Ragione sociale impresa partecipata</th>
          <th scope="col">Negatività</th>
          <th scope="col">Tipologia di proprieta</th>
          <th scope="col">C.F Partita IVA</th>
          <th scope="col" />
        </tr>
      </thead>
      {Array.isArray(data) && data.length > 0 && (
        <tbody>
          {data.map((item, itemIndex) => {
            if (Array.isArray(item.partecipate) && item.partecipate.length > 0) {
              return item.partecipate.map((element, index) => {
                const itemKey = `${itemIndex}-${index}`;
                return (
                  <tr
                    key={itemKey}
                    className={`${isClicked === itemKey ? "isClicked" : "default"}`}
                    onClick={() => {
                      if (itemKey === isClicked) {
                        setIsClicked(null);
                      } else {
                        setIsClicked(itemKey);
                      }
                      onElementSelect(element, index);
                    }}
                  >
                    <td>
                      {element.denominazioneNormP ? element.denominazioneNormP : element.nome + " " + element.cognome}
                      {/*<div className="ed-tooltip">*/}
                      {/*  Sono presenti eventi negativi, clicca per approfondire*/}
                      {/*  <span className="arrow"/>*/}
                      {/*</div>*/}
                    </td>
                    {renderNegativityFlag(negativityValue(element, premium))}
                    <td>{element.descDescrizionesede}</td>
                    <td>{element.codicefiscaleP}</td>
                    <td className="add-button-table">
                      <i className="bi bi-plus-circle" />
                      <i className="bi bi-dash-circle" />
                    </td>
                  </tr>
                );
              });
            }
            return null;
          })}
        </tbody>
      )}
    </Table>
  );
};

export default InvestmentsDetailsTable;
