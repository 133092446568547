import React from "react";
import classNames from "classnames";

const Upselling =
  ({
     className,
     title,
     description,
     redirectUrl,
     children,
     redirectDisabled = false,
     redirectButtonText = 'Acquista l’informazione',
     show = false
   }) => {
    const link = redirectDisabled === false ? redirectUrl : '#noaction';
    return (
      <React.Fragment>
        {children}
        {show && <div className={`content-wrapper ${className}`}>
          <div className="card-wrapper no-information-content">
            <h2 className="title">{title}</h2>
            <p className="description">{description}</p>
            <a href={link} className={classNames({
              "backToMagento": true,
              "btn": true,
              "see-other-location-button": true,
              "disabled": redirectDisabled
            })}>{redirectButtonText}</a>
          </div>
        </div>}
      </React.Fragment>
    )
  }


export default Upselling;
