import React from "react";
import { convertDate } from "../../utils/formaters";
import { formattedNumber } from "../../utils/formaters";

const TableBodySintezi = ({
  denominazione,
  codicefiscale,
  codfiscaleEsponente,
  tipo,
  evento,
  esponenti,
  flagProtesti,
  flagPregiudizievoli,
  flagProcedure,
  dataLevataRecente,
  dataPregRecente,
  dataPcorRecente,
  totImportoProtesti,
  totImportoPregiudizievoli,
  descPcorRecente,
  premium,
  showPremium,
  negativity,
  negativityData,
  verificato,
}) => {
  const renderPremiumCols = () => {
    if (premium) {
      return (
        <>
          <td className="table-td">
            {convertDate(dataLevataRecente)} <br />
            {convertDate(dataPregRecente)} <br />
            {convertDate(dataPcorRecente)}
          </td>
          <td className="table-td">
            {negativityData?.[0]?.numProtesti || "--"} <br />
            {negativityData?.[0]?.numPregTotale || "--"} <br />
            {negativityData?.[0]?.numPcor || "--"}
          </td>
          <td className="table-td">
            {formattedNumber(totImportoProtesti)} € <br />
            {formattedNumber(totImportoPregiudizievoli)} € <br />
            {descPcorRecente || "-"}
          </td>
        </>
      );
    } else if (showPremium) {
      return (
        <>
          <td className="table-td" />
          <td className="table-td" />
          <td className="table-td" />
        </>
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <td className="table-td">{denominazione ? denominazione : "-"}</td>
      {/*<td*/}
      {/*  className='table-td'>{esponenti ? codfiscaleEsponente ? codfiscaleEsponente : "-" : codicefiscale ? codicefiscale : "-"}</td>*/}
      <td className="table-td">{tipo ? tipo : "-"} </td>
      <td className="table-td">
        Protesti <br />
        Pregiudizievoli <br />
        Procedure
      </td>
      <td className="table-td">
        {verificato ? (
          <>
            {flagProtesti ? (flagProtesti === "S" ? "SI" : "NO") : "--"} <br />
            {flagPregiudizievoli ? (flagPregiudizievoli === "S" ? "SI" : "NO") : "--"} <br />
            {flagProcedure ? (flagProcedure === "S" ? "SI" : "NO") : "--"}
          </>
        ) : (
          "Non verificato"
        )}
      </td>
      {renderPremiumCols()}
    </>
  );
};

export default TableBodySintezi;
