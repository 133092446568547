import { Fragment } from "react";
import { convertDate } from "../../../utils/formaters";

const NegativityRows = ({ negativity, data, premium }) => {
  return (
    <Fragment>
      {negativity ? (
        <tr>
          <td>Negatività:</td>
          <td>Nessuna negatività riscontrata</td>
        </tr>
      ) : (
        <Fragment>
          {premium ? (
            <Fragment>
              <tr>
                <td colSpan={2}>PROTESTI</td>
              </tr>
              <tr>
                <td>Numero protesti:</td>
                <td>{data.numProtesti || "--"}</td>
              </tr>
              <tr>
                <td>Totale importo protesti:</td>
                <td>{data.totImportoProtesti || "--"}</td>
              </tr>
              <tr>
                <td>Data ultima levata:</td>
                <td>{convertDate(data.dataLevataRecente)}</td>
              </tr>
              <tr>
                <td colSpan={2}>PREGIUDIZIEVOLI</td>
              </tr>
              <tr>
                <td>Numero pregiudizievoli totali:</td>
                <td>{data.numPregTotale || "--"}</td>y
              </tr>
              <tr>
                <td>Numero pregiudizievoli annotate:</td>
                <td>{data.numPregAnnotate || "--"}</td>
              </tr>
              <tr>
                <td>Numero pregiudizievoli senza importo:</td>
                <td>{data.numPregNoImporto || "--"}</td>
              </tr>
              <tr>
                <td>Importo totale pregiudizievoli:</td>
                <td>{data.totImportoPregiudizievoli || "--"}</td>
              </tr>
              <tr>
                <td>Data pregiudizievole più recente:</td>
                <td>{convertDate(data.dataPregRecente)}</td>
              </tr>
              <tr>
                <td colSpan={2}>Nessuna procedura riscontrata</td>
              </tr>
              <tr>
                <td>Numero procedure:</td>
                <td>{data.numPcor || "--"}</td>
              </tr>
              <tr>
                <td>Codice procedura più recente:</td>
                <td>{data.codicePcorRecente || "--"}</td>
              </tr>
              <tr>
                <td>Data procedura più recente:</td>
                <td>{convertDate(data.dataPcorRecente)}</td>
              </tr>
            </Fragment>
          ) : (
            <Fragment>
              <tr>
                <td>PROTESTI</td>
                <td>{data.flagProtesti === "N" ? "Nessun protesto riscontrato" : `${data.flagProtesti}`}</td>
              </tr>
              <tr>
                <td>PREGIUDIZIEVOLI</td>
                <td>
                  {data.flagPregiudizievoli === "N"
                    ? "Nessuna pregiudizievole riscontrata"
                    : `${data.flagPregiudizievoli}`}
                </td>
              </tr>
              <tr>
                <td>PROCEDURE CONCORSUALI</td>
                <td>{data.flagProcedure === "N" ? "Nessuna procedura riscontrata" : `${data.flagProcedure}`}</td>
              </tr>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default NegativityRows;
