import React, { useState } from "react";
import { FormattedNumber, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import DetailModal from "./DetailModal";
import CardWrapper from "../../card";
import classNames from "classnames";

function DefaultDetails(props) {
  const { intl, values, compas_category, otherLocationData } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="col-md-8 details-content">
      <div className="row content-wrapper">
        <div className="col-md-6 first-content">
          <CardWrapper icon="metrics" title={intl.formatMessage({ id: "app.title_principal_branch_details" })}>
            <p>
              <strong>{values.lm_title}</strong>
              <br/> {values.lm_address}
              <br/> {values.email}
            </p>
            <hr/>
            <p>
              <strong>P.Iva:</strong> {values.lm_vatNumber} <br/>
              <strong>C.F.:</strong> {values.lm_fiscalCode} <br/>
              <strong>C.F. Gruppo:</strong> {values.lm_cfgroup}
              <br/>
              <strong>CCIAA REA:</strong> {values.lm_cciaa_rea}
            </p>
            <hr/>
            <p>
              <strong>Cod. Istat Comune:</strong> {values.lm_comuneIstatCode} <br/>
              <strong>Cod. Istat Provincia:</strong> {values.lm_lm_provinceIstatCode} <br/>
              <strong>Sez. Censimento:</strong> {values.lm_censusSection}
            </p>
            <hr/>
            <p>
              <strong>Dipendenti</strong>
              <br/>
              Anno e trimestre di riferimento ({values.year} - {values.lm_year_quarter}) <br/>
              Numero dipendenti ({values.lm_nr_employees}) <br/>
              Numero indipendenti ({values.lm_nr_independent})
            </p>
            <div className="content-spacer"/>
            {otherLocationData && (
              <button
                onClick={handleShow}
                className={classNames({
                  "see-other-location-button": true,
                  active: show,
                })}
              >
                Vedi altre sedi
                <span className="add-icon">
                  <i className="bi bi-plus-circle"/>
                  <i className="bi bi-dash-circle"/>
                </span>
              </button>
            )}
          </CardWrapper>
          <Modal show={show} onHide={handleClose} scrollable>
            <DetailModal handleClose={handleClose} otherLocationData={otherLocationData}/>
          </Modal>
        </div>
        <div className="col-md-6 ">
          <CardWrapper
            icon="info"
            title={intl.formatMessage({ id: "app.title_info_company" })}
            style={{ height: "100%" }}
          >
            <p>
              <strong>Stato Attività Infocamere</strong>
              <br/> {values.lm_activityStatus}
            </p>
            <hr/>
            <p>
              <strong>Data Protocollo: </strong> {values.lm_protocollDate} <br/>
              <strong>Data Protocollo Evaso: </strong> {values.lm_protocollDateProcessed} <br/>
              <strong>Data inizio attività: </strong> {values.lm_startActivityDate} <br/>
              <strong>Data iscrizione: </strong> {values.lm_registrationDate} <br/>
              <strong>Data cessazione: </strong> {values.lm_endDate} <br/>
            </p>
            <hr/>
            <p>
              <strong>Cap. Soc. Deliberato: </strong>
              {!isNaN(values.lm_capitalDeliberate) && (
                // eslint-disable-next-line react/style-prop-object
                <FormattedNumber value={values.lm_capitalDeliberate} style="currency" currency="EUR"/>
              )}
              <br/>
              <strong>Cap. Soc. Sottoscritto: </strong>
              {!isNaN(values.lm_capitalUndersigned) && (
                // eslint-disable-next-line react/style-prop-object
                <FormattedNumber value={values.lm_capitalUndersigned} style="currency" currency="EUR"/>
              )}
              <br/>
              <strong>Cap. Soc. Versato: </strong>
              {!isNaN(values.lm_capitalOut) && (
                // eslint-disable-next-line react/style-prop-object
                <FormattedNumber value={values.lm_capitalOut} style="currency" currency="EUR"/>
              )}
              <br/>
              <strong>Conferimenti: </strong>
              {!isNaN(values.lm_contributions) ? (
                // eslint-disable-next-line react/style-prop-object
                <FormattedNumber value={values.lm_contributions} style="currency" currency="EUR"/>
              ) : (
                "--"
              )}
              <br/>
            </p>
            <hr/>
            {/* <p>
              <strong>Codice Rae</strong> ({values.lm_raeCode}) <br />
              <strong>Codice Sae</strong> ({values.lm_saeCode}) <br />
            </p>
            <hr /> */}
          </CardWrapper>
        </div>
        <div className="content-spacer"/>
        <div className="col-md-12 col-mt-md col-mt-sm col-mt-xs">
          <CardWrapper
            icon="merchandise"
            title={intl.formatMessage({ id: "app.title_merchandise_section_and_category_info" })}
          >
            <div className="row">
              <div className="col-md-12">
                <b> Codice ATECO </b>({values.codeAteco}): {values.descAteco}
                <br/>
                <b>Codice SIC</b> ({values.lm_sicCode}): {values.lm_sicDesc}
                <br/>
                <b>Kompass</b> (
                {compas_category && compas_category.fpCompassCodeCategory0
                  ? compas_category.fpCompassCodeCategory0
                  : " --"}
                ) : {compas_category && compas_category.fpCompassCategory0 ? compas_category.fpCompassCategory0 : " --"}
                <br/>
                <b>Kompass </b>(
                {compas_category && compas_category.fpCompassCodeCategory1
                  ? compas_category.fpCompassCodeCategory1
                  : "-"}
                ) : {compas_category && compas_category.fpCompassCategory1 ? compas_category.fpCompassCategory1 : " --"}
                <br/>
                <b>Kompass</b> (
                {compas_category && compas_category.fpCompassCodeCategory2
                  ? compas_category.fpCompassCodeCategory2
                  : "--"}
                ) : {compas_category && compas_category.fpCompassCategory2 ? compas_category.fpCompassCategory2 : " --"}
              </div>
              <div className="col-md-12">
                <strong>Codice Rae</strong> ({values.lm_raeCode}): {values.data.descRae || " --"}
                <br/>
                <strong>Codice Sae</strong> ({values.lm_saeCode}): {values.data.descSae} <br/>
              </div>
            </div>
          </CardWrapper>
        </div>
      </div>
    </div>
  );
}

DefaultDetails.propTypes = {
  intl: PropTypes.any,
};

export default injectIntl(DefaultDetails);
