import React from "react";
import { Table as TableB } from "react-bootstrap";

import TableHeadSintezi from "./TableHeadSintezi";
import TableBodySintezi from "../tableSintesi/TableBodySintezi";

import { v4 as uuid } from "uuid";

const TableSintezi = ({ negativity, modelSoci, modelEsponeti, sociPremium, esponentiPremium }) => {
  return (
    <TableB responsive>
      <thead>
        <tr>
          <TableHeadSintezi showPremium={sociPremium || esponentiPremium} />
        </tr>
      </thead>
      <tbody>
        {Array.isArray(modelSoci) && (
          <>
            {modelSoci.map((elSoci) => (
              <tr key={uuid()}>
                <TableBodySintezi
                  negativity={negativity}
                  {...elSoci}
                  premium={sociPremium}
                  showPremium={sociPremium || esponentiPremium}
                />
              </tr>
            ))}
          </>
        )}
        {Array.isArray(modelEsponeti) && (
          <>
            {modelEsponeti.map((elEsponeti) => (
              <tr key={uuid()}>
                <TableBodySintezi
                  negativity={negativity}
                  esponenti
                  {...elEsponeti}
                  premium={esponentiPremium}
                  showPremium={sociPremium || esponentiPremium}
                />
              </tr>
            ))}
          </>
        )}
      </tbody>
    </TableB>
  );
};

export default TableSintezi;
