import React from "react";
import { Table } from "react-bootstrap";
import NegativityRows from "../../../table/__partials/negativity-rows";
import { formattedNumber } from "../../../../utils/formaters";

const QuotesRows = ({ data }) =>
  Array.isArray(data) &&
  data.map((quota) => (
    <React.Fragment>
      <tr>
        <td colSpan={2}>QUOTE</td>
      </tr>
      <tr>
        <td>Tipo Diritto:</td>
        <td>{quota.descCodiceProp}</td>
      </tr>
      <tr>
        <td>Quota Percentuale Parziale:</td>
        <td>{formattedNumber(quota.percentualeQuotaParziale)}</td>
      </tr>
      <tr>
        <td>Quota Percentuale Consolidata:</td>
        <td>{formattedNumber(quota.percentualeQuotaOutput)}</td>
      </tr>
      <tr>
        <td>Importo Quota Consolidata:</td>
        <td>{formattedNumber(quota.importoQuotaCons)}</td>
      </tr>
      <tr>
        <td>Valore Nominale:</td>
        <td>{formattedNumber(quota.valoreQuotaNominaleCons)}</td>
      </tr>
    </React.Fragment>
  ));

const DetailsOfMember = ({ detailsPerson, premium = false }) => {
  return (
    <Table responsive hover className="personDetails-table">
      <thead>
        <tr>
          <th colSpan={2}>{detailsPerson.denominazioneNorm}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Residente a:</td>
          <td>{detailsPerson.comune || "--"}</td>
        </tr>
        <tr>
          <td>Codice fiscale:</td>
          <td>{detailsPerson.codicefiscale || "--"}</td>
        </tr>
        <tr>
          <td>Indirizzo:</td>
          <td>{detailsPerson.indirizzoNorm || "--"}</td>
        </tr>
        <tr>
          <td>Partita IVA:</td>
          <td>{detailsPerson.partitaiva || "--"}</td>
        </tr>
        <tr>
          <td>Cciaa Rea:</td>
          <td>{[detailsPerson.cciaaPg, detailsPerson.reaPg].join(" ").trim() || "--"}</td>
        </tr>
        {Array.isArray(detailsPerson.quote) && <QuotesRows data={detailsPerson.quote} />}
        {premium && (
          <tr>
            <td>Score:</td>
            <td>
              {Array.isArray(detailsPerson.negativita) && detailsPerson.negativita[0].fascia
                ? detailsPerson.negativita[0].fascia
                : "--"}
            </td>
          </tr>
        )}
        {premium && Array.isArray(detailsPerson.negativita) && (
          <NegativityRows
            data={detailsPerson.negativita[0]}
            negativity={detailsPerson.negativita[0].negativita === "N"}
            premium={premium}
          />
        )}
        {!premium && Array.isArray(detailsPerson.flag) && (
          <NegativityRows
            data={detailsPerson.flag[0]}
            negativity={detailsPerson.flag[0].negativita === "N"}
            premium={premium}
          />
        )}
      </tbody>
    </Table>
  );
};
export default DetailsOfMember;
