const ratingTransformer = (state) => {
  if (Array.isArray(state.documents.values.outputRatingFido) && state.documents.values.outputRatingFido[0]) {
    return {
      rating: state.documents.values.outputRatingFido[0],
    };
  }

  return {
    rating: null,
  };
};

export default ratingTransformer;
