const TableHead = () => (
  <thead>
    <tr>
      <th>Evento</th>
      <th>Data</th>
      <th>Numero eventi</th>
      <th>Valore totale/Tipologia</th>
    </tr>
  </thead>
);

export default TableHead;
