import React from "react";

const DimensionalInfo = ({ socialCapital, employees, billed, ebitda, cashFlow }) => (
  <p>
    <strong>Capitale sociale:</strong> {socialCapital}<br/>
    <strong>Dipendenti:</strong> {employees} <br/>
    <strong>Fatturato:</strong> {billed} <br/>
    <strong>Ebitda:</strong> {ebitda} <br/>
    <strong>Cash Flow:</strong> {cashFlow} <br/>
  </p>
);

export default DimensionalInfo;