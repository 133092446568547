const mergeNegativity = (state, dataSoci) => {
  const sociRaggruppati = state.documents.values?.soci_premium?.listaSociPremium?.sociRaggruppati;

  if (dataSoci && Array.isArray(sociRaggruppati) && sociRaggruppati.length > 0) {
    return dataSoci.map((item) => {
      const sociItem = sociRaggruppati.find((i) => i.denominazione === item.denominazione);
      const negativity = sociItem?.negativita;
      if (negativity) {
        return {
          ...item,
          ...{
            negativityData: negativity,
          },
        };
      }

      return item;
    });
  }

  return dataSoci;
};

const sinteziSoci = (state) => {
  let dataSoci = null;
  let sociPremium = false;

  if (typeof state.documents.values.soci_premium !== "undefined" && state.documents.values.soci_premium) {
    dataSoci =
      state.documents.values.soci_premium.listaSociPremium &&
      Array.isArray(state.documents.values.soci_premium.listaSociPremium.listaNegativita)
        ? state.documents.values.soci_premium.listaSociPremium.listaNegativita
        : [];
    sociPremium = true;
    dataSoci = mergeNegativity(state, dataSoci);
  }

  if (!dataSoci && typeof state.documents.values.soci !== "undefined" && state.documents.values.soci) {
    dataSoci =
      state.documents.values.soci.listaSoci && Array.isArray(state.documents.values.soci.listaSoci.listaFlag)
        ? state.documents.values.soci.listaSoci.listaFlag
        : [];
    sociPremium = false;
  }

  return {
    dataSoci,
    sociPremium,
  };
};

export default sinteziSoci;
