import React from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Table from "components/table/Table";
import { budgetFourDIc } from "utils/transformers";
import { convertDate } from "utils/formaters";

const Ultimi = () => {
  const {
    tableDataUltimiConto,
    tableDataUltimiStatoAttivo,
    tableDataUltimiStatoPassivo,
    bilancioChiuso,
    bilancioTipo,
    bilancioComparto,
  } = useSelector((state) => budgetFourDIc(state));

  return (
    <Row>
      {(tableDataUltimiStatoAttivo || tableDataUltimiStatoPassivo || tableDataUltimiConto) && (
        <Col>
          <div className="main">
            <Row>
              <Col>
                <h1 className="analyze-title mb-5">Ultimi bilanci disponibili</h1>
              </Col>
            </Row>
            {(tableDataUltimiStatoAttivo || tableDataUltimiStatoPassivo) && (
              <>
                <Row>
                  <Col md={5}>
                    <div className="ultimi-info">
                      <div className="mb-1">
                        <span className="font-weight-bold">Bilancio chiuso il: </span>
                        <span>{bilancioChiuso ? convertDate(bilancioChiuso) : "--"}</span>
                      </div>
                      <div className="mb-1">
                        <span className="font-weight-bold">Tipo: </span>
                        <span>{bilancioTipo ? bilancioTipo : "--"}</span>
                      </div>
                      <div className="mb-1">
                        <span className="font-weight-bold">Comparto: </span>
                        <span>{bilancioComparto ? bilancioComparto : "--"}</span>
                      </div>
                    </div>
                  </Col>
                  <Col md={7}>
                    <h3 className="font-weight-bold">Stato Patrimoniale</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {tableDataUltimiStatoAttivo && <Table model={tableDataUltimiStatoAttivo} />}
                    {tableDataUltimiStatoPassivo && <Table model={tableDataUltimiStatoPassivo} />}
                  </Col>
                </Row>
              </>
            )}
            {tableDataUltimiConto && (
              <Row>
                <Col>
                  <h3 className="font-weight-bold text-center">Conto Economico</h3>
                  <Table model={tableDataUltimiConto} />
                </Col>
              </Row>
            )}
          </div>
        </Col>
      )}
    </Row>
  );
};

export default Ultimi;
