import React from "react";
import { useSelector } from "react-redux";
import { sinteziSoci, sinteziEsponenti, ratingTransformer } from "utils/transformers";
import { Row, Col } from "react-bootstrap";
import { injectIntl } from "react-intl";
import TableSintezi from "components/tableSintesi/TableSintezi";
import CardWrapper from "components/card";
import RatingSwitch from "components/rating/ratingSwitch";
import TableCompanySummary from "../../tableCompanySummary";
import negativityTransformer from "../../../utils/transformers/negativity";
import Upselling from "components/upselling";
import useProductUpsellign from "../../../utils/hooks/useProductUpsellign";
import { formattedNumber } from "../../../utils/formaters";
import useProductsAvailable from "../../../utils/hooks/useProductsAvailable";
import useDocumentAvailable from "../../../utils/hooks/useDocumentAvailable";

const RatingImg = ({ linearGraph }) => (
  <div className="d-flex w-100 justify-content-center align-content-center align-items-center">
    <img src={`data:image/png;base64,${linearGraph}`} alt="Rating bar"/>
  </div>
)

const Valuta = ({ intl }) => {
  const { dataSoci, sociPremium } = useSelector((state) => sinteziSoci(state));
  const { dataEsponenti, esponentiPremium } = useSelector((state) => sinteziEsponenti(state));
  // eslint-disable-next-line no-unused-vars
  const { rating } = useSelector((state) => ratingTransformer(state));
  const { negativity } = useSelector((state) => negativityTransformer(state));
  const negativityColor = () => {
    const color = Array.isArray(negativity.fascia.split(" ")) ? negativity.fascia.split(" ")[0] : "";
    switch (color) {
      case "G":
        return { middle: true };
      case "V":
        return { top: true };
      case "R":
        return { bottom: true };
      default:
        return null;
    }
  };

  const budgetGradeColor = () => {
    const color = rating?.descClasseScoreBilancio;
    switch (color) {
      case "giallo":
        return { middle: true };
      case "verde":
        return { top: true };
      case "rosso":
        return { bottom: true };
      default:
        return null;
    }
  }

  const isRatingAvailableInProducts = useProductsAvailable('rating_fido');
  const isRatingAvailableInDocuments = useDocumentAvailable('outputRatingFido');
  const isNegativityAvailableInUpselling = useProductUpsellign("negativita");
  const isRatingAvailableInUpselling = isRatingAvailableInProducts && !isRatingAvailableInDocuments;//useProductUpsellign("rating");
  const isExponentAvailableInUpselling = useProductUpsellign(["esponenti_premium", "esponenti"]);
  const isSociAvailableInUpselling = useProductUpsellign(["soci_premium", "soci"]);

  const linearGraph = rating?.linearGraph;

  return (
    <>
      <Row>
        <Col md={12} style={{ backgroundColor: "#e7f8ff" }}>
          <br/>
          <div className="content-spacer"/>
        </Col>
        <Col className="mt-3">
          <div className="main">
            <Row className="mb-5">
              <Upselling
                className="col-md-9"
                show={isRatingAvailableInUpselling}
                title={"Informazioni per la valutazione dell’azienda"}
                description={
                  "Scopri l’affidabilità dell’azienda tramite il nostro rating e il fido commerciale consigliato."
                }
                redirectUrl={""}
              >
                {!isRatingAvailableInUpselling && (
                  <React.Fragment>
                    <Col md={3}>
                      <h5 className="text-center mb-3">
                        {intl.formatMessage({ id: "app.evaluate.rating_title" })}:{" "}
                        <strong>{rating && rating.classeRating ? rating.classeRating : "-"}</strong>
                      </h5>
                      <div className="rating-section valuta-rating">
                        {linearGraph && (<RatingImg linearGraph={linearGraph}/>)}
                        <p className="pt-4">{rating?.descClasseRating ? rating.descClasseRating : '--'}</p>
                        {/*<RatingBar rate={25} thin/>*/}
                      </div>
                      <br/>
                      <p className="valuta-text">{intl.formatMessage({ id: "app.evaluate.rating_desc" })}</p>
                    </Col>
                    <Col md={3}>
                      <CardWrapper
                        icon="commercial-credit-line"
                        title={intl.formatMessage({ id: "app.evaluate.commercial_credit_line_title" })}
                      >
                        <div className="valute-card">
                          <h2>
                            <strong>{rating && rating.fido ? formattedNumber(rating.fido) : "-"} €</strong> {/* rating.fido */}
                          </h2>
                          <p>{intl.formatMessage({ id: "app.evaluate.commercial_credit_line_desc" })}</p>
                        </div>
                      </CardWrapper>
                    </Col>
                    <Col md={3}>
                      <h5 className="text-center mb-3">
                        {intl.formatMessage({ id: "app.evaluate.grade_budget_title" })}:{" "}
                        <strong>{rating && rating.scoreBilancio ? rating.scoreBilancio : "-"}</strong>
                      </h5>
                      <div className="valuta-rating">
                        <RatingSwitch {...budgetGradeColor()}/>
                      </div>
                      <p className="valuta-text">{intl.formatMessage({ id: "app.evaluate.grade_budget_desc" })}</p>
                    </Col>
                  </React.Fragment>
                )}
                {!isNegativityAvailableInUpselling && (<Col md={3}>
                  {negativity && (
                    <>
                      <h5 className="text-center mb-3">
                        {intl.formatMessage({ id: "app.evaluate.negative_events_score_title" })}:{" "}
                        <strong>{negativity.fascia}</strong>
                      </h5>
                      <div className="valuta-rating">
                        <RatingSwitch {...negativityColor()} />
                      </div>
                      <p className="valuta-text">
                        {intl.formatMessage({ id: "app.evaluate.negative_events_score_desc" })}
                      </p>
                    </>
                  )}
                </Col>)}
              </Upselling>
              {isNegativityAvailableInUpselling && (<Upselling
                className="h-100"
                show={isNegativityAvailableInUpselling}
                title={"Eventi negativi"}
                description={"Score e sintesi degli eventi negativi sull’azienda"}
                redirectUrl={""}
              />)}
            </Row>
          </div>
        </Col>
      </Row>

      {negativity && (
        <Row>
          <Col>
            <div className="main">
              <Row>
                <Col>
                  <h3 className="sintesi-heading">
                    {intl.formatMessage({ id: "app.evaluate.table_company_summary_title" })}
                  </h3>
                  <TableCompanySummary/>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Upselling
          className="col-md-6 my-4"
          show={isSociAvailableInUpselling}
          title={"Eventi negativi"}
          description={"Score e sintesi degli eventi negativi sui soci"}
          redirectUrl={""}
        ></Upselling>
        <Upselling
          className="col-md-6 my-4"
          show={isExponentAvailableInUpselling}
          title={"Eventi negativi"}
          description={"Score e sintesi degli eventi negativi sugli esponenti"}
          redirectUrl={""}
        >
          {(dataEsponenti || dataSoci) && (
            <Col>
              <div className="main">
                <h3 className="sintesi-heading">
                  {intl.formatMessage({ id: "app.evaluate.table_negative_events_partners_exponents_title" })}
                </h3>
                <TableSintezi
                  negativity={negativity}
                  modelSoci={dataSoci}
                  modelEsponeti={dataEsponenti}
                  sociPremium={sociPremium}
                  esponentiPremium={esponentiPremium}
                />
              </div>
            </Col>
          )}
        </Upselling>
      </Row>
    </>
  );
};

export default injectIntl(Valuta);
