import { useSelector } from "react-redux";
import { ratingTransformer } from "utils/transformers";
import ToggleSwitch from "./toggleSwitch";
import { formattedNumber } from "../../utils/formaters";

const RatingImg = ({ linearGraph }) => (
  <div className="d-flex w-100 justify-content-center align-content-center align-items-center">
    <img className="img-fluid" src={`data:image/png;base64,${linearGraph}`} alt="Rating bar"/>
  </div>
)

const RatingLayoutOne = ({ checked, negativity }) => {
  const { rating } = useSelector((state) => ratingTransformer(state));
  const linearGraph = rating?.linearGraph;

  return (
    <div className="rating-section">
      <div className="rating-section-title">
        Rating: <strong>{rating && rating.classeRating ? rating.classeRating : "--"}</strong>
      </div>
      {/*<RatingBar rate={25} />*/}
      {linearGraph && (<RatingImg linearGraph={linearGraph}/>)}
      <p className="text-center" style={{ padding: "20px 0", width: "60%", margin: "0 auto" }}>
        {rating && rating.descClasseRating ? rating.descClasseRating : "--"}
      </p>
      {negativity && (
        <div className="text-center d-flex flex-row justify-content-center align-content-center align-items-center">
          Eventi negativi &nbsp;&nbsp;
          <ToggleSwitch checked={checked}/>
        </div>
      )}
      <p className="text-center" style={{ marginTop: "25px" }}>
        Fido commerciale: {rating && rating.fido ? formattedNumber(rating.fido) : "-"} €
      </p>
    </div>
  );
};

export default RatingLayoutOne;
