import { FormattedNumber } from "react-intl";

export const convertDate = (date) => {
  if (date && date.length > 0) {
    const [year, month, day] = date.split("-");
    return day.slice(0, 2) + "/" + month + "/" + year;
  }
  return "--";
};

// eslint-disable-next-line react/style-prop-object
export const formattedNumber = (number) =>
  // eslint-disable-next-line react/style-prop-object
  isNaN(number) || number === null ? "--" : <FormattedNumber value={number} style="decimal" minimumFractionDigits={2} />;
