import React from "react";
// import { formatNumber } from 'utils/commmon/helpers';
import { formattedNumber as formatNumber } from "../../utils/formaters";

const TableBody = ({
  styleDescription,
  descVoce,
  valueFirst,
  valueSecond,
  valueThird,
  cagr,
  codVoce,
  mediaSettoreFirst,
  annoThird,
  styleCagr,
  annoSecond,
}) => {
  const styleCAGR = (type) => {
    switch (type) {
      case "cagr_grather":
        return {
          style: {
            color: "#009e4f",
          },
          icon: <i className="bi bi-caret-up-fill" style={{ color: "#009e4f" }} />,
        };
      case "cagr_equal":
        return {
          style: {
            color: "#fe7f00",
          },
          icon: <i className="bi bi-circle-fill" style={{ color: "#fe7f00", fontSize: "12px" }} />,
        };
      case "cagr_minus":
        return {
          style: {
            color: "#c9211e",
          },
          icon: <i className="bi bi-caret-down-fill" style={{ color: "#c9211e" }} />,
        };
      default:
        return {
          style: null,
          icon: null,
        };
    }
  };

  return (
    <>
      <td className={styleDescription}>{descVoce}</td>
      {styleDescription === "style_titolo" || styleDescription === "class_titolo" ? (
        <>
          <td />
          <td />
          <td />
          {annoSecond && <td />}
          {annoThird && <td />}
        </>
      ) : (
        <>
          <td className="table-td">{formatNumber(valueFirst, codVoce)}</td>
          <td className="table-td" style={styleCAGR(styleCagr).style}>
            {cagr != null ? (
              <>
                {formatNumber(cagr, codVoce)}&nbsp;
                {styleCAGR(styleCagr).icon}
              </>
            ) : (
              "--"
            )}
          </td>
          <td className="table-td">{formatNumber(mediaSettoreFirst, codVoce)}</td>
          {annoSecond && <td className="table-td">{formatNumber(valueSecond, codVoce)}</td>}
          {annoThird && <td className="table-td">{formatNumber(valueThird, codVoce)}</td>}
        </>
      )}
    </>
  );
};
export default TableBody;
