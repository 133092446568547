import React from "react";
import { Table } from "react-bootstrap";
import { convertDate } from "utils/formaters";
import NegativityRows from "../../../table/__partials/negativity-rows";

const ChargesRows = ({ data }) => (
  <React.Fragment>
    {Array.isArray(data) &&
      data.map((item) => (
        <React.Fragment>
          <tr>
            <td colSpan={2}>{item.descCarica}</td>
          </tr>
          <tr>
            <td>Nominato con atto:</td>
            <td>{convertDate(item.dataAttoNomina)}</td>
          </tr>
          <tr>
            <td>Durata carica:</td>
            <td>{item.durata}</td>
          </tr>
          <tr>
            <td>Data inizio carica:</td>
            <td>{convertDate(item.dataInizioCarica)}</td>
          </tr>
          <tr>
            <td>Data fine carica:</td>
            <td>{convertDate(item.dataFine)}</td>
          </tr>
        </React.Fragment>
      ))}
  </React.Fragment>
);

const DetailsOfPerson = ({ detailsPerson, premium = false }) => {
  return (
    <Table id="details-of-person" responsive hover className="personDetails-table">
      <thead>
        <tr>
          <th colSpan={2}>{detailsPerson.denominazione}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Data di nascita:</td>
          <td>{convertDate(detailsPerson.dataNascita)}</td>
        </tr>
        <tr>
          <td>Luogo di nascita:</td>
          <td>{detailsPerson.comuneNascita}</td>
        </tr>
        <tr>
          <td>Codice fiscale:</td>
          <td>{detailsPerson.codfiscaleEsponente}</td>
        </tr>
        <tr>
          <td>Indirizzo:</td>
          <td>{detailsPerson.indirizzoNorm}</td>
        </tr>
        <tr>
          <td>Legale Rappresentante:</td>
          <td>{detailsPerson.flagLegaleRapp}</td>
        </tr>
        {detailsPerson.cariche && <ChargesRows data={detailsPerson.cariche} />}
        {premium && (
          <tr>
            <td>Score:</td>
            <td>{detailsPerson.negativita ? detailsPerson.negativita[0].fascia : ""}</td>
          </tr>
        )}
        {premium && Array.isArray(detailsPerson.negativita) && detailsPerson.negativita[0] && (
          <NegativityRows
            negativity={detailsPerson.negativita[0].negativita === "N"}
            data={detailsPerson.negativita[0]}
            premium={premium}
          />
        )}
        {!premium && Array.isArray(detailsPerson.flag) && detailsPerson.flag[0] && (
          <NegativityRows
            negativity={detailsPerson.flag[0].negativita === "N"}
            data={detailsPerson.flag[0]}
            premium={premium}
          />
        )}
      </tbody>
    </Table>
  );
};

export default DetailsOfPerson;
