import React from "react";

const TableHeadSintezi = ({ showPremium }) => {
  return (
    <>
      <th>Soggetto</th>
      {/*<th>Codice fiscale</th>*/}
      <th>Tipo</th>
      <th>Evento</th>
      <th>Negatività</th>
      {showPremium && (
        <>
          <th>Data</th>
          <th>Numero eventi</th>
          <th>Valore totale/Tipologia</th>
        </>
      )}
    </>
  );
};

export default TableHeadSintezi;
