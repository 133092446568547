import { Tab } from "react-bootstrap";
import LearnMore from "../tabs/learn-more";
import Analyze from "../tabs/analyze";
import Evaluate from "../tabs/evaluate";
import { useRef, useState } from "react";
import { useScrollPosition } from "../../utils/hooks/useScrollPosition";

const TabsPage = props => {
  const tabContentRef = useRef();
  const [hideOnScroll, setHideOnScroll] = useState();

  useScrollPosition(({ prevPos, currPos }) => {
    // scrolling to top
    const top = document.querySelector("#first-page .footer").getBoundingClientRect().top;
    if (prevPos.y >= currPos.y && top <= 34) {
      setHideOnScroll(false);
      props.onToggleShowTabs(true);
    }

    // scrolling to bottom
    if (prevPos.y <= currPos.y && top > 35) {
      setHideOnScroll(true);
      props.onToggleShowTabs(false);
    }
  }, [hideOnScroll], tabContentRef, false, 300)

  return (
    <Tab.Content ref={tabContentRef}>
      <Tab.Pane eventKey="learn-more">
        <LearnMore {...props} />
      </Tab.Pane>

      <Tab.Pane eventKey="analyze">
        <Analyze {...props} />
      </Tab.Pane>

      <Tab.Pane eventKey="evaluate">
        <Evaluate {...props} />
      </Tab.Pane>
    </Tab.Content>
  );
}

export default TabsPage;