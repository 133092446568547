import { useSelector } from "react-redux";
import productsAvailable from "../transformers/productsAvailable";

export const useProductsAvailable = (key) => {
  const availableProducts = useSelector((state) => productsAvailable(state));
  if (Array.isArray(key) && Array.isArray(availableProducts)) {
    for (let i = 0; i < key.length; i++) {
      const isAvailable = availableProducts.find((product) => product === key[i]) === key[i];
      if (isAvailable) {
        return true;
      }
    }
    return false;
  }

  return Array.isArray(availableProducts) && availableProducts.find((item) => item === key) === key;
};

export default useProductsAvailable;
