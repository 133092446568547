import { convertDate } from "utils/formaters";
import { FormattedNumber } from "react-intl";

const baseRegistry = (state) => {
  const data = state.documents.values.anagrafica_base || {};
  const title = data.denominazione;
  const subTitle = `${data.descGruppo ? data.descGruppo + "-" : ""} codice fiscale ${data.codicefiscale}`;
  const address = `${data.indirizzo || "-"}, ${data.comune || "-"}`;
  const phoneNumber = data.dbuPrefisso || data.dbuNumero ? `${data.dbuPrefisso || ""} ${data.dbuNumero || ""}` : "--";
  const email = data.descEmail || "--";
  const web = data.url || "--";
  // eslint-disable-next-line react/style-prop-object
  const socialCapital = data.capsocDeliberato ? (
    // eslint-disable-next-line react/style-prop-object
    <FormattedNumber value={data.capsocDeliberato} style="currency" currency="EUR" />
  ) : (
    "--"
  );
  const employees = data.addettiTotale || "--";
  // eslint-disable-next-line react/style-prop-object
  const billed = data.fatturato ? <FormattedNumber value={data.fatturato} style="currency" currency="EUR" /> : "--";
  // learn more tab -variables
  const lm_title = data.denominazioneNorm || "--";
  const lm_address = data.dug + " " + data.duf + " " + data.civico + " " + data.cap + " " + data.comune || "--";
  const lm_vatNumber = data.partitaiva || "--";
  const lm_fiscalCode = data.codicefiscale || "--";
  const lm_cfgroup = data.cfgruppo || "--";
  const lm_cciaa_rea = data.cciaa + "" + data.rea || "--";
  const lm_comuneIstatCode = data.codeIstatComune || "--";
  const lm_lm_provinceIstatCode = data.codeIstatProvincia || "--";
  const lm_censusSection = data.sezione || "--";
  const lm_year_quarter = data.trimestre || "--";
  const lm_nr_employees = data.addettiDipendenti || "--";
  const lm_nr_independent =
    data.addettiIndipendenti || data.addettiIndipendenti === 0 ? data.addettiIndipendenti : "--";
  const lm_activityStatus = data.descStatoAttivitaInfocamere || "--";
  const lm_protocollDate = data.dataProtocollo ? convertDate(data.dataProtocollo) : "--";
  const lm_protocollDateProcessed = data.dataProtocolloEvaso ? convertDate(data.dataProtocolloEvaso) : "--";
  const lm_startActivityDate = data.datainizioattivita ? convertDate(data.datainizioattivita) : "--";
  const lm_registrationDate = data.dataiscrizione ? convertDate(data.dataiscrizione) : "--";
  const lm_endDate = data.datacessazione ? convertDate(data.datacessazione) : "--";
  const lm_capitalDeliberate = data.capsocDeliberato || "--";
  const lm_capitalUndersigned = data.capsocSottoscritto || "--";
  const lm_capitalOut = data.capsocVersato || "--";
  const lm_contributions = data.conferimenti || "--";
  const lm_raeCode = data.codRae || "--";
  const lm_saeCode = data.codSae || "--";
  const lm_sicCode = data.codeSic || "--";
  const lm_sicDesc = data.descSic || "--";
  const codeAteco = data.codeAteco || "--";
  const descAteco = data.descAteco || "--";
  const year = data.anno || "";

  return {
    data,
    title,
    subTitle,
    address,
    phoneNumber,
    email,
    web,
    socialCapital,
    employees,
    billed,
    lm_address,
    lm_title,
    lm_vatNumber,
    lm_fiscalCode,
    lm_cfgroup,
    lm_cciaa_rea,
    lm_comuneIstatCode,
    lm_lm_provinceIstatCode,
    lm_censusSection,
    lm_year_quarter,
    lm_nr_employees,
    lm_nr_independent,
    lm_activityStatus,
    lm_protocollDate,
    lm_protocollDateProcessed,
    lm_startActivityDate,
    lm_registrationDate,
    lm_capitalDeliberate,
    lm_capitalUndersigned,
    lm_capitalOut,
    lm_contributions,
    lm_raeCode,
    lm_saeCode,
    lm_sicCode,
    lm_sicDesc,
    lm_endDate,
    codeAteco,
    descAteco,
    year,
  };
};

export default baseRegistry;
