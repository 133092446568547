import { useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { budgetFourDIc } from "../../utils/transformers";
import CustomChart from "../chartjs/chart";

const BudgetChart1 = ({ intl }) => {
  const { graficoBilancio1 } = useSelector(state => budgetFourDIc(state));
  const data = JSON.parse(JSON.stringify(graficoBilancio1.data));
  const options = JSON.parse(JSON.stringify(graficoBilancio1.options));
  const type = JSON.parse(JSON.stringify(graficoBilancio1.type));

  data.datasets.forEach(ds => {
    ds.fill = false;
    ds.pointRadius = 5;
    ds.pointHoverRadius = 8;
  });

  return <CustomChart
    type={type}
    title={intl.formatMessage({ id: "app.analyze.chart1_title" })}
    options={options}
    data={data}
  />
}

export default injectIntl(BudgetChart1);