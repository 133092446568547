export const territorialCharts1 = (state) => {
  // if (data) {
  //   const backgroundColor = data.backgroundColor
  //     ? data.backgroundColor
  //     : ["rgba(153,207,245,0.5)", "rgba(60,141,191,0.5)", "rgba(255,233,148,0.5)", "rgba(122,203,162,0.5)"];
  //   const datasets = [{ ...data.datasets[0], backgroundColor }] || "--";
  //   const labels = data.labels || "--";
  //
  //   return {
  //     datasets,
  //     labels,
  //   };
  // }

  return typeof state.documents.values.indiciTerritoriali !== "undefined" &&
    state.documents.values.indiciTerritoriali &&
  state.documents.values.indiciTerritoriali.graficoIndiciTerritoriali1
    ? state.documents.values.indiciTerritoriali.graficoIndiciTerritoriali1
    : null;
};

export const territorialCharts2 = (state) => {
  // if (data) {
  //   const backgroundColor = data.backgroundColor
  //     ? data.backgroundColor
  //     : [
  //         "rgba(153,207,245,0.5)",
  //         "rgba(60,141,191,0.5)",
  //         "rgba(255,233,148,0.5)",
  //         "rgba(122,203,162,0.5)",
  //         "rgba(50,199,255,0.5)",
  //         "rgba(50,207,50,0.5)",
  //       ];
  //   const datasets = [{ ...data.datasets[0], backgroundColor }] || "--";
  //   const labels = data.labels || "--";
  //
  //   return {
  //     datasets,
  //     labels,
  //   };
  // }

  return typeof state.documents.values.indiciTerritoriali !== "undefined" &&
    state.documents.values.indiciTerritoriali &&
  state.documents.values.indiciTerritoriali.graficoIndiciTerritoriali2
    ? state.documents.values.indiciTerritoriali.graficoIndiciTerritoriali2
    : null;
};

export const territorialCharts3 = (state) => {
  // if (data) {
  //   const backgroundColor = data.backgroundColor
  //     ? data.backgroundColor
  //     : [
  //         "rgba(153,207,245,0.5)",
  //         "rgba(60,141,191,0.5)",
  //         "rgba(255,233,148,0.5)",
  //         "rgba(122,203,162,0.5)",
  //         "rgba(50,199,255,0.5)",
  //         "rgba(50,207,50,0.5)",
  //       ];
  //   const datasets = [{ ...data.datasets[0], backgroundColor }] || "--";
  //   const labels = data.labels || "--";
  //
  //   return {
  //     datasets,
  //     labels,
  //   };
  // }

  return typeof state.documents.values.indiciTerritoriali !== "undefined" &&
    state.documents.values.indiciTerritoriali &&
  state.documents.values.indiciTerritoriali.graficoIndiciTerritoriali3
    ? state.documents.values.indiciTerritoriali.graficoIndiciTerritoriali3
    : null;
};
