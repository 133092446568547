import { useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { budgetFourDIc } from "../../utils/transformers";
import CustomChart from "../chartjs/chart";

const BudgetChart3 = ({ intl }) => {
  const { graficoBilancio3 } = useSelector(state => budgetFourDIc(state));
  const data = JSON.parse(JSON.stringify(graficoBilancio3.data));
  const options = JSON.parse(JSON.stringify(graficoBilancio3.options));
  const type = JSON.parse(JSON.stringify(graficoBilancio3.type));

  data.datasets.forEach(ds => {
    ds.fill = false;
    ds.pointRadius = 5;
    ds.pointHoverRadius = 8;
  });

  return <CustomChart type={type} title={intl.formatMessage({ id: "app.analyze.chart3_title" })} options={options} data={data}/>
}

export default injectIntl(BudgetChart3);