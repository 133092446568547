import { RestClient } from "../utils/clients";

const fetchAll = async (match = {}) => {
  const path = `/${match.params.idOrder}/${match.params.idClient}`;
  return RestClient.get('/site-data' + path);
};

const DocumentService = {
  fetchAll,
};

export default DocumentService;
