import React, { useState, useRef } from "react";
import DetailsOfPerson from "./DetailsOfPerson";
import CardIcon from "../../../card-icon";
import { negativityValue, renderNegativityFlag } from "../../../../utils/commmon/helpers";

const ExponentsDetails = ({ returnToDefault, exponent_Data }) => {
  const detailsTableRef = useRef();
  const [fiscalCode, setFiscalCode] = useState("");
  const [detailsPerson, setDetailsPerson] = useState([]);
  const [isClicked, setIsClicked] = useState();

  const getTrClicked = (index) => {
    setIsClicked(index);
  };

  const handleTableDetailsClick = (element, index) => {
    if (fiscalCode && fiscalCode === element.codfiscaleEsponente) {
      setFiscalCode(null);
      setIsClicked(null);
    } else {
      setFiscalCode(element.codfiscaleEsponente);
      setDetailsPerson(element);
      getTrClicked(index);
      if (window.innerWidth <= 768) {
        if (detailsTableRef && detailsTableRef.current) {
          detailsTableRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    }
  };

  const formatCharges = (charges) => {
    return charges
      .map((item) => item.descCarica)
      .filter((i) => i)
      .join(", ");
  };

  return (
    <div className="col-md-8 details-content">
      <div className="content-wrapper">
        <div className="company-information rounded_borders">
          <div className="cart-title-wrapper mt-4">
            <CardIcon icon="participation" image />
            <div className="title-subtitle-wrapper">
              <p className="cart-title">Dettagli Esponenti</p>
            </div>
            <button className="close-icon" onClick={() => returnToDefault()}>
              <i className="bi bi-x-circle" />
            </button>
          </div>
          <div className="row">
            <div className="col-md-12 col-xl-6 table-responsive">
              <table className="table exponentsDetails-table table-hover">
                <thead>
                  <tr>
                    <th scope="col" width={200}>
                      Nome Cognome
                    </th>
                    <th scope="col">Negatività</th>
                    <th scope="col">Carica</th>
                    <th scope="col">Codice fiscale</th>
                    <th scope="col" />
                  </tr>
                </thead>

                <tbody>
                  {exponent_Data &&
                    exponent_Data.exponent_Data.map((element, index) => {
                      return (
                        <tr
                          key={index}
                          className={`item-row ${isClicked === index ? "isClicked" : "default"}`}
                          onClick={() => {
                            handleTableDetailsClick(element, index);
                          }}
                        >
                          <td>{element.denominazione}</td>
                          {renderNegativityFlag(negativityValue(element, exponent_Data.premium))}
                          <td>{formatCharges(element.cariche)}</td>
                          <td>{element.codfiscaleEsponente}</td>
                          <td className="add-button-table">
                            <i className="bi bi-plus-circle" />
                            <i className="bi bi-dash-circle" />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div ref={detailsTableRef} className="col-md-12 col-xl-6">
              {fiscalCode && fiscalCode !== "" && (
                <DetailsOfPerson detailsPerson={detailsPerson} premium={exponent_Data.premium} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExponentsDetails;
