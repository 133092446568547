import React from "react";
import { Container } from "react-bootstrap";

import Valuta from "./Valuta";

const Evaluate = () => {
  return (
    <div id="evaluate">
      <Container fluid>
        <Valuta />
      </Container>
    </div>
  );
};

export default Evaluate;
