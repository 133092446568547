import Chart from "chart.js";
import 'chartjs-plugin-colorschemes';
import "chartjs-plugin-zoom";
import { useEffect, useRef } from "react";

const withDefaultOptions = ({ title, plugins }) => {
  const options = {
    responsive: true,
    title: {
      display: !!title,
      text: title,
    },
    legend: {
      display: true,
      ...plugins.legend || {},
    },
    plugins: {
      zoom: {
        zoom: {
          enabled: true,
          mode: 'y',
        },
        pan: {
          enabled: true,
          mode: 'y'
        },
        ...plugins.zoom
      },
      colorschemes: {
        scheme: 'brewer.Paired12',
        override: true,
      }
    },
  };
  return options;
};

const CustomChart = ({ type = 'line', title, options, data, plugins = {}, ...rest }) => {
  const chartRef = useRef();
  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    const $chart = new Chart(ctx, {
      type,
      data: JSON.parse(JSON.stringify(data)),
      options: withDefaultOptions({ title, plugins }),
    });
    return function cleanup() {
      $chart.destroy();
    }
  });
  return (
    <canvas ref={chartRef} {...rest} />
  )
};

export default CustomChart;