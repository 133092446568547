import React, { useEffect, useMemo, useState } from "react";
import { FormControl, InputGroup, Table } from "react-bootstrap";
import CardIcon from "../../card-icon";

const DetailModal = ({ handleClose, otherLocationData }) => {
  const [getTrClicked, setGetTrClicked] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpenAll, setOpenAll] = useState(false);
  const [openTableRowIndexes, setOpenTableRowIndexes] = useState([]);

  const handleToggleAllTableRows = () => {
    if (isOpenAll) {
      setOpenTableRowIndexes([]);
      setOpenAll(!isOpenAll);
      setGetTrClicked(null);
    } else {
      const indexes = Object.keys(otherLocationData);
      setOpenTableRowIndexes(indexes);
      setOpenAll(true);
    }
  };

  const handleTrClicked = (index) => {
    if (index === getTrClicked) {
      setGetTrClicked(null);
      const indexes = openTableRowIndexes.filter((item) => item !== index.toString());
      setOpenTableRowIndexes(indexes);
    } else {
      if (isOpenAll || openTableRowIndexes.includes(index.toString())) {
        setGetTrClicked(null);
        const indexes = openTableRowIndexes.filter((item) => item !== index.toString());
        setOpenTableRowIndexes(indexes);
      } else {
        setGetTrClicked(index);
        setOpenTableRowIndexes([...openTableRowIndexes, ...[index.toString()]]);
      }
    }
  };

  const handleSearch = (item, text) => {
    const dug = item.dug ? item.dug.toLowerCase() : "";
    const duf = item.duf ? item.duf.toLowerCase() : "";
    const civico = item.civico ? item.civico.toLowerCase() : "";
    const cap = item.cap ? item.cap.toLowerCase() : "";
    const comune = item.comune ? item.comune.toLowerCase() : "";
    const addressString = item.dug + " " + item.duf + " " + item.civico + " " + item.cap + " " + item.comune;

    if (
      dug.search(text) !== -1 ||
      duf.search(text) !== -1 ||
      civico.search(text) !== -1 ||
      cap.search(text) !== -1 ||
      comune.search(text) !== -1
    ) {
      return true;
    }

    return addressString.toLowerCase().search(text) !== -1;
  };

  const filteredRecords = useMemo(() => {
    if (searchQuery.length > 0) {
      return otherLocationData.filter((item) => {
        const searchText = searchQuery.toLowerCase();
        return handleSearch(item, searchText);
      });
    }

    return otherLocationData;
  }, [searchQuery, otherLocationData]);

  useEffect(() => {
    const indexes = Object.keys(otherLocationData);
    if (indexes.every((v) => openTableRowIndexes.includes(v))) {
      setOpenAll(true);
    } else {
      setOpenAll(false);
    }
  }, [openTableRowIndexes, otherLocationData]);

  return (
    <div className="modal-body">
      <div className="head-office-details rounded_borders">
        <div className="cart-title-wrapper">
          <CardIcon icon="metrics" image />
          <div className="title-subtitle-wrapper">
            <p className="cart-title">Dettagli altre sedi</p>
          </div>
          <button className="close-icon" onClick={() => handleClose()}>
            <i className="bi bi-x-circle" />
          </button>
        </div>
        <div className="titles-of-details-wrapper">
          <div className="search-button-wrapper">
            <div className="search-button mt-2">
              <InputGroup hasValidation={false}>
                <InputGroup.Append
                  as={"label"}
                  for="search_other_branches"
                  className="justify-content-center align-content-center align-items-center m-0"
                >
                  <i className="bi bi-search" />
                </InputGroup.Append>
                <FormControl
                  className="search-input"
                  id="search_other_branches"
                  type="text"
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                  placeholder="Avvia la ricerca"
                />
              </InputGroup>
            </div>
          </div>
        </div>
        <Table hover responsive className="exponentsDetails-table">
          <thead>
            <tr className={`${isOpenAll ? "isClicked" : ""}`} onClick={() => handleToggleAllTableRows()}>
              <td colSpan="2" className="border-none">
                SEDE
              </td>
              <td className="add-button-table right-button border-none" colSpan={2}>
                <i className="bi bi-plus-circle" />
                <i className="bi bi-dash-circle" />
              </td>
            </tr>
          </thead>
          <tbody>
            {filteredRecords &&
              Array.isArray(filteredRecords) &&
              filteredRecords.map((element, index) => {
                return (
                  <>
                    <tr
                      className={`${
                        getTrClicked === index || openTableRowIndexes.includes(index.toString())
                          ? "isClicked isOpened"
                          : "default"
                      }`}
                      onClick={() => handleTrClicked(index)}
                    >
                      <td colSpan={2} className="first-details-row">
                        {
                          element.indirizzo +
                            ", " +
                            element.cap +
                            " " +
                            element.localita +
                            " (" +
                            element.codProvincia +
                            ")"
                          // " " +
                          // element.comune || "--"
                        }
                      </td>
                      <td className="add-button-table right-button" colSpan={2}>
                        <i className="bi bi-plus-circle" />
                        <i className="bi bi-dash-circle" />
                      </td>
                    </tr>
                    <tr
                      className={
                        getTrClicked === index || openTableRowIndexes.includes(index.toString())
                          ? "open-details-person"
                          : "hide"
                      }
                    >
                      <td colSpan="4" className="details-table-wrapper">
                        <table className="table details-person-table">
                          <tbody>
                            <tr>
                              <th>Indirizzo</th>
                              <td>
                                {
                                  element.indirizzo +
                                    ", " +
                                    element.cap +
                                    " " +
                                    element.localita +
                                    " (" +
                                    element.codProvincia +
                                    ")"
                                  // " " +
                                  // element.comune || "--"
                                }
                              </td>
                            </tr>
                            <tr>
                              <th>Contatti</th>
                              <td>
                                {element.dbuPrefisso || ""} {element.dbuNumero || ""}
                              </td>
                            </tr>
                            <tr>
                              <th>Tipologia di sede</th>
                              <td>{element.descTipoSede}</td>
                            </tr>
                            <tr>
                              <th>Stato Attività</th>
                              <td>{element.descStatoAttivitaInfocamere}</td>
                            </tr>
                            <tr>
                              <th>Descrizione Ateco</th>
                              <td>{element.descAteco}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default DetailModal;
