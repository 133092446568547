import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Header from "../partial/header";
import Wrapper from "../partial/wrapper";
import CardWrapper from "../card";
import PageHeader from "../partial/page-header";
import BranchAddressContactInfo from "../card/content/branchAddressContactInfo";
import { baseRegistry, budgetFourDIc } from "../../utils/transformers/index";
import DimensionalInfo from "../card/content/dimensionalInfo";
import BranchLocationMap from "../card/content/branchLocationMap";
import MerchandiseSectionAndCategory from "../card/content/merchandiseSectionAndCategory";
import compassCategory from "../../utils/transformers/compasCategory";
import territorialIndices from "../../utils/transformers/territorialIndices";
import negativityTransformer from "../../utils/transformers/negativity";
import RatingWithUpSelling from "../card/ratingWithUpSelling";
import TerritoryAndSectorInfoWithUpSelling from "../card/territoryAndSectorInfoWithUpSelling";

class FirstPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  checkNegativity() {
    const {
      negativityData: { negativity, negativity_flag },
    } = this.props;
    const neg = negativity || negativity_flag;
    return neg.negativita === "S";
  }

  render() {
    const {
      intl,
      registry,
      budget,
      compassCategory,
      territorialIndices,
      negativityData: { negativity, negativity_flag },
    } = this.props;
    const neg = negativity || negativity_flag;
    return (
      <section id="first-page" style={{ minHeight: "100vh" }} className="first-page-pb">
        <Header {...this.props} />
        <Wrapper style={{ marginBottom: "15px" }}>
          <PageHeader title={registry.title} subTitle={registry.data.descGruppo} />
          <Row>
            <Col xs={12} sm={12} md={6} lg={4}>
              <CardWrapper icon="branch-location" title={intl.formatMessage({ id: "app.title_principal_branch" })}>
                <BranchAddressContactInfo {...registry} />
              </CardWrapper>
              <CardWrapper
                icon="dimensional-info"
                title={intl.formatMessage({ id: "app.title_dimensional_info" })}
                style={{ marginTop: "30px" }}
              >
                <DimensionalInfo {...registry} {...budget} />
              </CardWrapper>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="col-mt-sm col-mt-xs">
              <CardWrapper noPadding style={{ height: "100%" }}>
                <BranchLocationMap />
              </CardWrapper>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4} className="col-mt-md col-mt-sm col-mt-xs">
              <CardWrapper
                icon="section-and-category-merchandise"
                title={intl.formatMessage({ id: "app.title_merchandise_section_and_category" })}
                style={{ height: "100%" }}
              >
                <MerchandiseSectionAndCategory {...compassCategory} {...registry} />
              </CardWrapper>
            </Col>
          </Row>
          <div className="content-spacer" />
          <Row>
            <Col md={4}>
              <RatingWithUpSelling checked={neg && this.checkNegativity()} negativity={neg}/>
            </Col>
            <Col>
              <TerritoryAndSectorInfoWithUpSelling territorialIndices={territorialIndices} />
            </Col>
          </Row>
        </Wrapper>
        <footer className="footer" />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  registry: baseRegistry(state),
  budget: budgetFourDIc(state),
  compassCategory: compassCategory(state),
  territorialIndices: territorialIndices(state),
  negativityData: negativityTransformer(state),
});

export default connect(mapStateToProps)(injectIntl(FirstPage));
