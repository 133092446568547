import { Polar as PolarArea } from "react-chartjs-2";
import 'chartjs-plugin-colorschemes';
import { useDefaultCustomColorScheme } from "../../utils/commmon/helpers";

const EconomicPotentialChart = ({ data }) => {
  const options = useDefaultCustomColorScheme({
    responsive: true,
    legend: {
      display: true,
      position: "bottom",
    },
    elements: {
      r: {
        color: "red",
      },
      arc: {
        color: "#ff0",
      },
    },
  });

  return (
    <PolarArea
      data={JSON.parse(JSON.stringify(data.data))}
      width={100}
      options={options}
    />
  )
}

export default EconomicPotentialChart;
