import TerritoryAndSectorInfo from "./content/territoryAndSectorInfo";
import CardWrapper from "./index";
import React from "react";
import Upselling from "../upselling";
import useProductsAvailable from "../../utils/hooks/useProductsAvailable";
import useDocumentAvailable from "../../utils/hooks/useDocumentAvailable";

const TerritoryAndSectorInfoWithUpSelling = ({ territorialIndices }) => {
  const isAvailableInProducts = useProductsAvailable('territorio_indici');
  const isAvailableInDocuments = useDocumentAvailable('indiciTerritoriali');
  const isAvailableForUpSelling = !isAvailableInDocuments && isAvailableInProducts;

  return (
    <Upselling
      show={!isAvailableInDocuments && isAvailableInProducts}
      className="h-100"
      title="Informazioni sul territorio e settore"
      description="Indicatori di potenzialità e rischiosità del territorio in cui opera l’azienda comparati con la media di settore e di zona">
      {!isAvailableForUpSelling && <CardWrapper>
        <TerritoryAndSectorInfo {...territorialIndices} />
      </CardWrapper>}
    </Upselling>
  );
}

export default TerritoryAndSectorInfoWithUpSelling;
