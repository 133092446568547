import { Polar as PolarArea } from "react-chartjs-2";
import 'chartjs-plugin-colorschemes';
import { useDefaultCustomColorScheme } from "../../utils/commmon/helpers";

const NationalAverageNegativeEventsPerSectorChart = ({ data }) => {
  const options = useDefaultCustomColorScheme({
    legend: {
      position: "bottom",
    },
  });

  return (
    <PolarArea
      width={100}
      data={JSON.parse(JSON.stringify(data.data))}
      options={options}
    />
  );
};

export default NationalAverageNegativeEventsPerSectorChart;
