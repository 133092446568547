import React from "react";
import { Table as TableB } from "react-bootstrap";

import TableHead from "./TableHead";
import TableBody from "./TableBody";

import { v4 as uuid } from "uuid";

const Table = ({ model }) => {
  const annoThird = Array.isArray(model)&& model[1] ? model[1].annoThird : null;
  const annoSecond = Array.isArray(model)&& model[1] ? model[1].annoSecond : null;
  return (
    <TableB responsive>
      {Array.isArray(model) &&
      <>
        <thead>
        <tr>
          <TableHead {...model[1]} />
        </tr>
        </thead>
        <tbody>
        {model.map((el) => (
          <tr
            key={uuid()}
            className={!el.ordinamento === 1 ? "table-tr" : null}
          >
            {el.ordinamento !== 1 ? (
              <td className="td-spacing">
                <div className="td-tag"/>
              </td>
            ) : (
              <td/>
            )}
            <TableBody {...el} annoThird={annoThird} annoSecond={annoSecond}/>
          </tr>
        ))}
        </tbody>
      </>
      }
    </TableB>
  );
};

export default Table;
