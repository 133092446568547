import React, { useRef, useState } from "react";
import { Image } from "react-bootstrap";
import logo from "../../../../dettagli-sede.png";
import DetailsOfInvestment from "./DetailsOfInvestment";
import InvestmentsDetailsTable from "./table/InvestmentsDetailsTable";

const InvestmentsDetails = (props) => {
  const detailsTableRef = useRef();
  const { participationData, returnToDefault, premium } = props;
  const [fiscalCode, setFiscalCode] = useState("");
  const [detailsPerson, setDetailsPerson] = useState([]);
  const [isClicked, setIsClicked] = useState();

  const getTrClicked = (index) => {
    setIsClicked(index);
  };

  const handleTableDetailsClick = (element, index) => {
    if (fiscalCode && (fiscalCode === element.codicefiscaleP || fiscalCode === 'fiscal_code_' + index)) {
      setFiscalCode(null);
      setIsClicked(null);
    } else {
      // quick fix for elements without codicefiscale
      if (!element.codicefiscaleP) {
        setFiscalCode('fiscal_code_' + index);
      }
      setFiscalCode(element.codicefiscaleP);
      setDetailsPerson(element);
      getTrClicked(index);
      if (window.innerWidth <= 768) {
        if (detailsTableRef && detailsTableRef.current) {
          detailsTableRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    }
  };
  return (
    <div className="col-md-8 details-content">
      <div className="content-wrapper">
        <div className="company-information rounded_borders">
          <div className="cart-title-wrapper mt-4">
            <Image fluid src={logo}/>
            <div className="title-subtitle-wrapper">
              <p className="cart-title">Dettagli Partecipazioni</p>
            </div>
            <button className="close-icon" onClick={() => returnToDefault()}>
              <i className="bi bi-x-circle"/>
            </button>
          </div>
          <div className="row">
            <div className="col-md-12 col-xl-6 table-responsive">
              <InvestmentsDetailsTable
                data={participationData}
                onElementSelect={(elem, index) => handleTableDetailsClick(elem, index)}
                isClicked={isClicked} premium={premium}/>
            </div>
            <div className="col-md-12 col-xl-6">
              {fiscalCode && fiscalCode !== "" &&
              <DetailsOfInvestment detailsPerson={detailsPerson} premium={premium}/>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentsDetails;
