import useProductsAvailable from "./useProductsAvailable";
import useDocumentAvailable from "./useDocumentAvailable";

const useProductUpselling = (key) => {
  const isAvailableInProducts = useProductsAvailable(key);
  const isAvailableInDocuments = useDocumentAvailable(key);

  return isAvailableInProducts && !isAvailableInDocuments;
};

export default useProductUpselling;
