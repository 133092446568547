const compassCategory = state => {
  const data = state.documents.values.categorieKompass || {};
  if (Array.isArray(data) && data.length > 0) {
    const fpCompassCodeCategory0 = data[0].codeCategoria || '';
    const fpCompassCategory0 = data[0].descCategoria || '';
    const fpCompassCodeCategory1 = data[1] && data[1].codeCategoria ? data[1].codeCategoria : '--';
    const fpCompassCategory1 = data[1] && data[1].descCategoria ? data[1].descCategoria : '--';
    const fpCompassCodeCategory2 = data[2] && data[2].codeCategoria ? data[2].codeCategoria : '--';
    const fpCompassCategory2 = data[2] && data[2].descCategoria ? data[2].descCategoria : '--';
    return {
      fpCompassCodeCategory0,
      fpCompassCategory0,
      fpCompassCodeCategory1,
      fpCompassCategory1,
      fpCompassCodeCategory2,
      fpCompassCategory2
    };
  }

  return null;
};

export default compassCategory;