import React from "react";
import Chart from "chart.js";

export const formatNumber = (number, codVoce) => {
  const codVoces = [
    "IDX_TITLE_GFX1",
    "IDX_TITLE_GFX2",
    "IDX_TITLE_GFX3",
    "IDX_TITLE_SF",
    "IDX_SF_TITLE",
    "IDX_TITLE_REDD",
    "IDX_TREND_TITLE",
    "IDX_PRO_TITLE",
    "STATO",
    "IC_TITOLO",
  ];
  return codVoce && codVoces.includes(codVoce)
    ? ""
    : (number && number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")) || "-";
};

export const negativityValue = (element, premium) => {
  if (premium) {
    return element.negativita;
  } else {
    return element.flag;
  }
}

export const renderNegativityFlag = (negativity, premium = false) => {
  if (negativity && Array.isArray(negativity) && negativity.length > 0) {
    let showNegativity = false;
    for (let i = 0; i < negativity.length; i++) {
      if (negativity[i].negativita !== "N") {
        showNegativity = true;
        break;
      }
    }

    return showNegativity ? (
      <td className="flagIcon">
        <i className="bi bi-circle-fill" style={{ color: "red" }} />
      </td>
    ) : (
      <td className="flagIcon ">
        <i className="bi bi-circle-fill" style={{ color: "green" }} />
      </td>
    );
  }

  return <td className="flagIcon" />;
};

export const useDefaultCustomColorScheme = (options) => {
  if (!options) {
    return options;
  }

  if (!options.plugins) {
    options.plugins = {};
  }

  const fillAlpha = 0.5;
  const colorschemes = options.plugins.colorschemes
    ? JSON.parse(JSON.stringify(options.plugins.colorschemes))
    : {
        scheme: "brewer.Paired12",
        override: true,
      };

  colorschemes.custom = (scheme) => {
    const helpers = Chart.helpers;
    const overrideColors = scheme.map((color) => helpers.color(color).alpha(fillAlpha).rgbString());
    return overrideColors;
  };

  options.plugins.colorschemes = colorschemes;

  return options;
};
