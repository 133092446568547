import { FormattedNumber } from "react-intl";

const budgetFourDIc = (state) => {
  const data =
    state.documents.values.bilanci4dIc &&
    state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato &&
    state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato.bilanci4dIcRaggruppati
      ? state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato.bilanci4dIcRaggruppati
      : {};
  const graficoBilancioOne =
    state.documents.values.bilanci4dIc &&
    state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato &&
    state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato.graficoBilancio1
      ? state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato.graficoBilancio1
      : {};
  const graficoBilancioTwo =
    state.documents.values.bilanci4dIc &&
    state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato &&
    state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato.graficoBilancio2
      ? state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato.graficoBilancio2
      : {};
  const graficoBilancioThree =
    state.documents.values.bilanci4dIc &&
    state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato &&
    state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato.graficoBilancio3
      ? state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato.graficoBilancio3
      : {};
  const bilancioChiuso =
    state.documents.values.bilanci4dIc &&
    state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato &&
    state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato.riassuntoBilanci[0]
      ? state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato.riassuntoBilanci[0].dataChiusura
      : "";
  const bilancioTipo =
    state.documents.values.bilanci4dIc &&
    state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato &&
    state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato.riassuntoBilanci[0]
      ? state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato.riassuntoBilanci[0].tipo
      : "";
  const bilancioComparto =
    state.documents.values.bilanci4dIc &&
    state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato &&
    state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato.riassuntoBilanci[0]
      ? state.documents.values.bilanci4dIc.bilancio4dIcRaggruppato.riassuntoBilanci[0].comparto
      : "";

  let ebitda = null;
  let cashFlow = null;
  let graficoBilancioOne_one = null;
  let graficoBilancioOne_two = null;
  let graficoBilancioOne_three = null;
  let BilancioTwo_one = null;
  let BilancioTwo_two = null;
  let BilancioTwo_three = null;
  let BilancioTwo_four = null;
  let BilancioTwo_five = null;
  let BilancioTwo_six = null;
  let tableDataBilancioOne = null;
  let tableDataBilancioTwo = null;
  let tableDataUltimiConto = null;
  let tableDataUltimiStatoAttivo = null;
  let tableDataUltimiStatoPassivo = null;
  let graficoBilancio1 = {};
  let graficoBilancio2 = {};
  let graficoBilancio3 = {};

  if (Array.isArray(data)) {
    const dataEbitda = data.find((item) => item.codVoce === "IDX_EBITDA");
    const dataCashFlow = data.find((item) => item.codVoce === "IDX_CF");
    ebitda = dataEbitda.valueFirst ? (
      // eslint-disable-next-line react/style-prop-object
      <FormattedNumber value={dataEbitda.valueFirst} style="currency" currency="EUR" />
    ) : (
      "--"
    );
    cashFlow = dataCashFlow.valueFirst ? (
      // eslint-disable-next-line react/style-prop-object
      <FormattedNumber value={dataCashFlow.valueFirst} style="currency" currency="EUR" />
    ) : (
      "--"
    );
    //Table bilancio 1.1
    graficoBilancioOne_one = data
      .filter((item) => item.codVoce === "IDX_TITLE_GFX1" || item.groupVoce === "IDX_GFX1")
      .sort((a, b) => a.ordinamento - b.ordinamento);
    graficoBilancioOne_two = data
      .filter((item) => item.codVoce === "IDX_TITLE_GFX2" || item.groupVoce === "IDX_GFX2")
      .sort((a, b) => a.ordinamento - b.ordinamento);
    graficoBilancioOne_three = data
      .filter((item) => item.codVoce === "IDX_TITLE_GFX3" || item.groupVoce === "IDX_GFX3")
      .sort((a, b) => a.ordinamento - b.ordinamento);
    tableDataBilancioOne = [...graficoBilancioOne_one, ...graficoBilancioOne_two, ...graficoBilancioOne_three];
    //Table bilancio 1.2
    BilancioTwo_one = data
      .filter((item) => item.codVoce === "IDX_COF_TITLE" || item.groupVoce === "IDX_COF")
      .sort((a, b) => a.ordinamento - b.ordinamento);
    BilancioTwo_two = data
      .filter((item) => item.codVoce === "IDX_TITLE_SF" || item.groupVoce === "IDX_SF")
      .sort((a, b) => a.ordinamento - b.ordinamento);
    BilancioTwo_three = data
      .filter((item) => item.codVoce === "IDX_SF_TITLE" || item.groupVoce === "IDX_GC")
      .sort((a, b) => a.ordinamento - b.ordinamento);
    BilancioTwo_four = data
      .filter((item) => item.codVoce === "IDX_TITLE_REDD" || item.groupVoce === "IDX_REDD")
      .sort((a, b) => a.ordinamento - b.ordinamento);
    BilancioTwo_five = data
      .filter((item) => item.codVoce === "IDX_TREND_TITLE" || item.groupVoce === "IDX_TREND")
      .sort((a, b) => a.ordinamento - b.ordinamento);
    BilancioTwo_six = data
      .filter((item) => item.codVoce === "IDX_PRO_TITLE" || item.groupVoce === "IDX_PROD")
      .sort((a, b) => a.ordinamento - b.ordinamento);
    tableDataBilancioTwo = [
      ...BilancioTwo_one,
      ...BilancioTwo_two,
      ...BilancioTwo_three,
      ...BilancioTwo_four,
      ...BilancioTwo_five,
      ...BilancioTwo_six,
    ];
    //Table Ultimi bilanci conto
    tableDataUltimiConto = data.filter((item) => item.groupVoce === "CE").sort((a, b) => a.ordinamento - b.ordinamento);
    //Table Ultimi bilanci stato Attivo
    tableDataUltimiStatoAttivo = data
      .filter((item) => item.codVoce === "IC_TITOLO" || item.groupVoce === "SPA")
      .sort((a, b) => a.ordinamento - b.ordinamento);
    //Table Ultimi bilanci stato Passivo
    tableDataUltimiStatoPassivo = data
      .filter((item) => item.groupVoce === "SPP")
      .sort((a, b) => a.ordinamento - b.ordinamento);
    // tableDataUltimiStatoPassivo = [{
    //   codVoce: "STATO",
    //   descVoce: "STATO PATRIMONIALE PASSIVO",
    //   styleDescription: "class_titolo",
    //   ordinamento: 1
    // }, ...tableDataUltimiStatoPassivo]
    //Chart graficoBilancio
    graficoBilancio1 = graficoBilancioOne; //{labels1: graficoBilancioOne.data.labels, datasets1: graficoBilancioOne.data.datasets.map(el => ({...el, borderColor: "#aaaafe",tension: 0.4,borderWidth: 1}))}
    graficoBilancio2 = graficoBilancioTwo; // {labels2: graficoBilancioTwo.data.labels, datasets2: graficoBilancioTwo.data.datasets.map(el => ({...el, borderColor: "orange",tension: 0.4,borderWidth: 1}))}
    graficoBilancio3 = graficoBilancioThree; // {labels3: graficoBilancioThree.data.labels, datasets3: graficoBilancioThree.data.datasets.map(el => ({...el, borderColor: "green",tension: 0.4,borderWidth: 1}))}
  }

  return {
    bilancioTipo,
    bilancioComparto,
    bilancioChiuso,
    ebitda,
    cashFlow,
    tableDataBilancioOne,
    tableDataBilancioTwo,
    tableDataUltimiConto,
    tableDataUltimiStatoAttivo,
    tableDataUltimiStatoPassivo,
    graficoBilancio1,
    graficoBilancio2,
    graficoBilancio3,
  };
};

export default budgetFourDIc;
