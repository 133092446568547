import { createAsyncThunk } from '@reduxjs/toolkit';
import DocumentService from "../../services/documents";

const getDocuments = createAsyncThunk(
  'documents/getDocuments',
  async (match) => {
    const response = await DocumentService.fetchAll(match);
    return response.data;
  }
)

export { getDocuments }
