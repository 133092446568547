import React from "react";

const MerchandiseSectionAndCategory = ({
  fpCompassCodeCategory0,
  fpCompassCategory0,
  fpCompassCodeCategory1,
  fpCompassCategory1,
  codeAteco,
  descAteco,
}) => (
  <div style={{ padding: "20px 0" }}>
    <p>
      <strong>Ateco 2007 ({codeAteco}): </strong>
      {descAteco}
    </p>
    <br />
    <p>
      <strong>Kompass ({fpCompassCodeCategory0 || "-"}): </strong>
      {fpCompassCategory0 || "-"}
    </p>
    <br />
    <p>
      <strong>Kompass ({fpCompassCodeCategory1 || "-"}): </strong>
      {fpCompassCategory1 || "-"}
    </p>
  </div>
);

export default MerchandiseSectionAndCategory;
