export const membersDetails = (state) => {
  // const data =
  //   state.documents.values.soci && state.documents.values.soci.listaSoci
  //     ? state.documents.values.soci.listaSoci.sociRaggruppati
  //     : state.documents.values.soci_premium && state.documents.values.soci_premium.listaSociPremium
  //     ? state.documents.values.soci_premium.listaSociPremium.sociRaggruppati
  //     : {};

  let data = null;
  let premium = false;
  if (state.documents.values.soci_premium && state.documents.values.soci_premium.listaSociPremium) {
    data = state.documents.values.soci_premium.listaSociPremium.sociRaggruppati;
    premium = true;
  } else if (state.documents.values.soci && state.documents.values.soci.listaSoci) {
    data = state.documents.values.soci.listaSoci.sociRaggruppati;
    premium = false;
  } else {
    data = null;
    premium = false;
  }

  if (data !== null && Array.isArray(data) && data.length > 0) {
    const memberData = data;
    return {
      memberData,
      premium,
    };
  }
  return null;
};

export const memberDetailsChart = (state) => {
  // const data =
  //   state.documents.values.soci && state.documents.values.soci.listaSoci && typeof state.documents.values.soci.listaSoci.graficoSoci !== "undefined"
  //     ? state.documents.values.soci_premium.listaSociPremium.graficoSoci
  //     : null;

  let data = null;

  if (state.documents.values.soci_premium && state.documents.values.soci_premium.listaSociPremium) {
    data = state.documents.values.soci_premium.listaSociPremium.graficoSoci;
  } else if (state.documents.values.soci && state.documents.values.soci.listaSoci) {
    data = state.documents.values.soci.listaSoci.graficoSoci;
  } else {
    data = null;
  }

  // if (data.datasets) {
  //   const backgroundColor = data.backgroundColor
  //     ? data.backgroundColor
  //     : [
  //         "rgba(153,207,245,0.5)",
  //         "rgba(60,141,191,0.5)",
  //         "rgba(255,233,148,0.5)",
  //         "rgba(122,203,162,0.5)",
  //         "rgba(50,199,255,0.5)",
  //       ];
  //   const datasets = [{ ...data.datasets[0], backgroundColor }] || "--";
  //   const labels = data.labels || "--";
  //   return {
  //     datasets,
  //     labels,
  //   };
  // }
  return data;
};
