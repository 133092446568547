import React from "react";

const PageHeader = ({ title, subTitle }) => (
  <header className="page-header">
    <h1>{title}</h1>
    {subTitle && <p>{`Principale azionista: ${subTitle}`}</p>}
  </header>
);

export default PageHeader;
