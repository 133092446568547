const otherLocation = (state) => {
  const data = state.documents.values.altre_sedi ? state.documents.values.altre_sedi : {};
  if (Array.isArray(data) && data.length > 0) {
    const otherLocationData = data;
    return {
      otherLocationData,
    };
  }
  return null;
};
export default otherLocation;
