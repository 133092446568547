import { Switch, Route, Redirect } from "react-router-dom";
import { Tab } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FirstPage from "./components/page/FirstPage";
import TabsPage from "./components/page/TabsPage";
import Scroll from "components/scroll/Scroll";
import { getDocuments } from "./store/actions/documentActions";
import { PageLoader } from "./components/loader";
import { useLocation, matchPath } from "react-router";

const App = (props) => {
  const [activeTab, setActiveTab] = useState("learn-more");
  // eslint-disable-next-line no-unused-vars
  const [showTabs, setShowTabs] = useState(true);
  const dispatch = useDispatch();
  const { loading, error, requestCompleted } = useSelector((state) => state.documents);
  const [showScroll, setShowScroll] = useState(false);
  const location = useLocation();

  const matchPath1 = matchPath(location.pathname, {
    path: '/:idOrder/:idClient',
    exact: true,
    strict: true
  });

  const matchPath2 = matchPath(location.pathname, {
    path: '/:idOrder/:idClient/:tab',
    exact: true,
    strict: true
  });

  useEffect(() => {
    if (!requestCompleted) {
      dispatch(getDocuments(matchPath1 || matchPath2));
    }
  }, [dispatch, requestCompleted, matchPath1, matchPath2]);

  useEffect(() => {
  }, [showTabs]);

  useEffect(() => {
    function onScroll() {
      if (window.scrollY >= 400) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    }

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (loading) {
    return <PageLoader/>;
  }
  if (error) {
    return <div>Error: {JSON.stringify(error)}</div>;
  }

  // TODO: replace loading & error screen with proper components
  return (
    <>
      <Tab.Container defaultActiveKey="learn-more" activeKey={activeTab}>
        <FirstPage onSwitchTab={(tab) => {
          setActiveTab(tab);
        }} {...props} showTabs={showTabs}/>
        <Switch>
          <Route exact path="/" render={() => (<div>404 No found</div>)}/>
          <Route exact path="/:idOrder/:idClient"
                 render={() => <Redirect to={{
                   pathname: `${location.pathname}/learn-more`
                 }}/>}/>
          <Route
            exact
            path="/:idOrder/:idClient/:tab"
            render={(routeProps) => (
              <TabsPage
                {...routeProps}
                {...props}
                onToggleShowTabs={(show) => {
                  // setShowTabs(show)
                }}
              />
            )}
          />
        </Switch>
      </Tab.Container>
      {showScroll && <Scroll onClick={scrollTop}/>}
    </>
  );
};

export default App;
