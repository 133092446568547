const negativityTransformer = state => {
  const data = Array.isArray(state.documents.values.negativita) && state.documents.values.negativita.length > 0
    ? state.documents.values.negativita : [];

  const negativity_flag = Array.isArray(state.documents.values.negativita_flag) && state.documents.values.negativita_flag.length > 0
    ? state.documents.values.negativita_flag : [];

  return {
    negativity: data[0],
    negativity_flag: negativity_flag[0],
  }
}

export default negativityTransformer;