import React from 'react';
import classNames from 'classnames'

const Tag = ({ children, blue, orange, green }) => {
  const classes = classNames('tags', {
    blue,
    orange, 
    green, 
  })
  return <div className={classes}>{children}</div>;
}

export default Tag
